//This function technically does nothing, but it can be useful for making sure 
//that the name you specified is actually a key of the thing you are working with.
export function nameof<T>(key: keyof T, instance?: T) {
  return key;
}

/**
  * This function will convert a string to a number. If the string is not a valid number it will return the value provided in default.
  * If strict is true, the empty string will be considered an invalid number, instead of 0
  */
export function numberOrDefault(str: string, defaultNum: number, strict = false) {
  if(strict && str === '')
  {
    return defaultNum;
  }
  let result = Number(str);
  if(Number.isNaN(result))
  {
    return defaultNum;
  } else {
    return result;
  }
}
import { ReactNode } from "react";
import { DeveloperError } from "@errors/DeveloperError";

interface IFormRowProps {
  mwfResponsiveClasses?: string[];
  mwfColumnClasses?: string[];
}

/**
 * See https://moray-prod.azurewebsites.net/components/detail/form-row.html for reference
 */
export const FormRow: React.FC<IFormRowProps> = (props) => {

  function renderChild(node: ReactNode, index?: number): JSX.Element {
    if (typeof node !== 'object') {
      throw new DeveloperError(`Invalid child in FormRow with type: ${typeof props.children}`);
    }
    const classes = props.mwfColumnClasses?.join(" ") ?? "col";
    const key = index !== undefined ? `form_${index}` : undefined;
    return (
      <div className={classes} key={key}>
        {node}
      </div>
    );
  }

  function renderChildren() {
    let result: JSX.Element | JSX.Element[] | null
    if (props.children instanceof Array) {
      result = props.children.map(renderChild);
    } else if (props.children === null) {
      result = null;
    } else {
      result = renderChild(props.children);
    }
    return result;
  }

  function render() {

    const classes = props.mwfResponsiveClasses ?? [];
    classes.push("form-row");

    return (
      <div className={classes.join(" ")}>
        {renderChildren()}
      </div>
    )
  }

  return render();
}
import { HTTPError } from "@errors/HttpError";
import { LanguagePack } from "@intl/LanguagePack";
import { Locale } from "@intl/Locale";
import { HttpStatusCode } from "@utils/Http/HttpStatusCode";

export namespace LocaleAPI {
  export async function getSupportedNativeNames() {
    let response = await fetch('/api/v1/locale/get/supported/nativeNames');
    if (response.ok) {
      let data: { [loc: string]: string } = await response.json();
      return data;
    } else {
      throw new HTTPError(response.status, "Failed to get native names");
    }
  }

  export async function getLanguagePack(locale: Locale) {
    const url = `/api/v1/locale/get/${locale}`;
    let response = await fetch(url);
    if (response.ok) {
      let data: LanguagePack = await response.json();
      return data;
    } else if (response.status === HttpStatusCode.NotFound) {
      throw new HTTPError(response.status, "Could not find language pack for locale: " + locale);
    } else {
      //TODO show a universal error page.
      throw new HTTPError(response.status, "Failed to load any language, try clearing your cache.");
    }
  }
}
export namespace ProductFilterConst
{
    export const SearchParam = "search";
    export const SearchTypeParam = "type";
    export const CatalogFilters = "cat";
    export const AfterDate = "afterDate";
    export const BeforeDate = "beforeDate";
    export const AlphaSortType = "alphaSort";
    export const AlphaSortKey = "alphaSortKey";
    export const DateSortType = "dateSort";
    export const ScoringProfile = "scoringProfile";
    export const OSFilter = "os";
    export const ArchFilter = "arch";
    export const ArtifactFilter = "artifact";
    export const DefaultSortKey = "a-z,Name";
    export const DescAlphaSortKey = "z-a,Name";
    export const RelevanceSortKey = "rel";
    export const SearchTypeExact="exact";
    export const SearchTypePartial="partial";
    export const LatestDateSortKey="date_desc";
    export const OldestDateSortKey="date_asc";
    export const SearchAllKey="*";

    export const enum FilterCollection
    {
      Category,
      OS,
      Architecture,
      Artifact
    }
}

export interface IProductFilter {
  searchQuery?: string;
  catFilters:string[];
  dateRangeFilter: boolean;
  afterDate:Date | null | undefined;
  beforeDate:Date | null | undefined;
  searchType?:string;
  dateSortType?:string;
  scoringProfile?:string;
  osFilter:string[];
  archFilter: string[];
  artifactFilter: string[];
  selectedSortKey: string;
}

export class ProductFilter implements IProductFilter
{
  searchQuery?: string;
  searchType?:string;
  catFilters:string[];
  dateRangeFilter: boolean;
  afterDate:Date | null | undefined;
  beforeDate:Date | null | undefined;
  dateSortType?:string;
  scoringProfile?:string;
  osFilter:string[];
  archFilter: string[];
  artifactFilter: string[];
  selectedSortKey: string;

  constructor(urlParams?:URLSearchParams)
  {
    // Search Query Parsing
    this.searchQuery = urlParams?.get(ProductFilterConst.SearchParam) ?? undefined;
    // Search Type Filter Parsing (Exact/Partial)
    this.searchType = urlParams?.get(ProductFilterConst.SearchTypeParam) ?? undefined;
    // Default to partial if we have a search and no search type
    if(this.searchQuery && !this.searchType)
    {
      this.searchType = ProductFilterConst.SearchTypePartial;
    }
    // Selected Sort Key Default to relevance if search query is present
    this.selectedSortKey = this.searchQuery? ProductFilterConst.RelevanceSortKey : ProductFilterConst.DefaultSortKey;
    // Category Filter Parsing
    this.catFilters = urlParams?.get(ProductFilterConst.CatalogFilters)?.split(",") ?? [];
    // OS Filter Parsing
    this.osFilter = urlParams?.get(ProductFilterConst.OSFilter)?.split(",") ?? [];
    // Arch Filter Parsing
    this.archFilter = urlParams?.get(ProductFilterConst.ArchFilter)?.split(",") ?? [];
    // Artifact Filter Parsing
    this.artifactFilter = urlParams?.get(ProductFilterConst.ArtifactFilter)?.split(",") ?? [];
    // Date Range Filter Parsing
    var afterDate = urlParams?.get(ProductFilterConst.AfterDate) ?? undefined;
    var beforeDate = urlParams?.get(ProductFilterConst.BeforeDate) ?? undefined;
    this.dateRangeFilter = (afterDate || beforeDate)? true : false;
    // After Date 
    this.afterDate= afterDate ? new Date(afterDate) : undefined;
    // Before Date 
    this.beforeDate= beforeDate ? new Date(beforeDate) : undefined;
    // Alpha Sort Key Filter Parsing
    var alphaSort = urlParams?.get(ProductFilterConst.AlphaSortType) ?? undefined;
    if(alphaSort)
    {
      // Default to default alpha sort key
      this.selectedSortKey =  alphaSort=="asc" ? ProductFilterConst.DefaultSortKey : ProductFilterConst.DescAlphaSortKey;
      var alphaSortKey = urlParams?.get(ProductFilterConst.AlphaSortKey) ?? undefined;
      // If the alpha sort key is different than name set it
      if(alphaSortKey != undefined && alphaSortKey != "Name")
      {
        this.selectedSortKey =  this.selectedSortKey.split(",")[0] + alphaSortKey;
      }
    }
    // Date Sort Key Filter Parsing
    var dateSort = urlParams?.get(ProductFilterConst.DateSortType) ?? undefined;
    if(dateSort != undefined)
    {
      this.selectedSortKey =  dateSort=="asc" ? ProductFilterConst.OldestDateSortKey : ProductFilterConst.LatestDateSortKey;
    }

  }

  public static toURIQuery(productFilter:ProductFilter)
  {
    var queryParams="?";
    var argExists = false;

    // Search Query Arg
    if(productFilter.searchQuery)
    {
      queryParams+=`${ProductFilterConst.SearchParam}=${productFilter.searchQuery}`;

      // Default search type if we dont have one set
      let searchType = productFilter.searchType ? productFilter.searchType : ProductFilterConst.SearchTypePartial; 
      queryParams+=`&${ProductFilterConst.SearchTypeParam}=${searchType}`;
      
      argExists = true;
    }

    // Catalog Filter Arg
    if(productFilter.catFilters && productFilter.catFilters.length>0)
    {

      if(argExists)
      {
        queryParams+="&";
      }
      else
      {
        argExists = true;
      }

      
      queryParams+=`${ProductFilterConst.CatalogFilters}=`;
      var cnt = 1;
      productFilter.catFilters.forEach(filter =>{

        queryParams+=filter;
        if(cnt<productFilter.catFilters.length)
        {
          queryParams+=",";
        }
        ++cnt;

      });

    }

    if(productFilter.dateRangeFilter)
    {
        // Before Date Arg
        if(productFilter.beforeDate!== undefined && productFilter.beforeDate!==null )
        {
            if(argExists)
            {
                queryParams+="&";
            }
            else
            {
                argExists = true;
            }
            queryParams+=`${ProductFilterConst.BeforeDate}=${productFilter.beforeDate.toISOString()}`;
        }
        
        // After Date Arg
        if(productFilter.afterDate!== undefined && productFilter.afterDate!==null )
        {
            if(argExists)
            {
                queryParams+="&";
            }
            else
            {
                argExists = true;
            }
            queryParams+=`${ProductFilterConst.AfterDate}=${productFilter.afterDate.toISOString()}`;
        }
    }

    // Sort Key
    if(productFilter.selectedSortKey!== ProductFilterConst.RelevanceSortKey)
    {
        // The key is alpha/date currently so if we add more sort types we will need to update this logic
        var sortKey = productFilter.selectedSortKey.includes("date")? ProductFilterConst.DateSortType : productFilter.selectedSortKey.includes(",")? ProductFilterConst.AlphaSortType : "";
        var sortValue = "";
        // Alpha/Date Sort Type Arg
        if(productFilter.selectedSortKey.includes("a-z") || productFilter.selectedSortKey===ProductFilterConst.OldestDateSortKey)
        {
            sortValue = "asc";
        }
        else if(productFilter.selectedSortKey.includes("z-a") || productFilter.selectedSortKey===ProductFilterConst.LatestDateSortKey)
        {
            sortValue = "desc";
        }

        if(argExists)
        {
            queryParams+="&";
        }
        else
        {
            argExists = true;
        }
        queryParams+=`${sortKey}=${sortValue}`;

        // Alpha Sort Key Arg
        if(productFilter.selectedSortKey.includes(","))
        {
          if(argExists)
          {
            queryParams+="&";
          }
          else
          {
            argExists = true;
          }
          // Currently we set the alpha sort with "a-z" or "z-a" and the sort key designated with , key
          queryParams+=`${ProductFilterConst.AlphaSortKey}=${productFilter.selectedSortKey.split(",")[1]}`;
        }
    
    }

    // Keyword Boost Scoring Profile Arg
    if(productFilter.scoringProfile)
    {
      if(argExists)
      {
        queryParams+="&";
      }
      else
      {
        argExists = true;
      }

      queryParams+=`${ProductFilterConst.ScoringProfile}=${productFilter.scoringProfile}`;
    }

    // OS Filter Arg
    if(productFilter.osFilter && productFilter.osFilter.length>0)
    {
      if(argExists)
      {
        queryParams+="&";
      }
      else
      {
        argExists = true;
      }
      queryParams+=`${ProductFilterConst.OSFilter}=`;
      var cnt = 1;
      productFilter.osFilter.forEach(os =>{

        queryParams+=os;
        if(cnt<productFilter.osFilter.length)
        {
          queryParams+=",";
        }
        ++cnt;

      });
    }

    // Arch Filter Arg
    if(productFilter.archFilter && productFilter.archFilter.length>0)
    {
      if(argExists)
      {
        queryParams+="&";
      }
      else
      {
        argExists = true;
      }
      queryParams+=`${ProductFilterConst.ArchFilter}=`;
      var cnt = 1;
      productFilter.archFilter.forEach(arch =>{

        queryParams+=arch;
        if(cnt<productFilter.archFilter.length)
        {
          queryParams+=",";
        }
        ++cnt;

      });
    }

    // Artifact Filter Arg
    if(productFilter.artifactFilter && productFilter.artifactFilter.length>0)
    {
      if(argExists)
      {
        queryParams+="&";
      }
      else
      {
        argExists = true;
      }
      queryParams+=`${ProductFilterConst.ArtifactFilter}=`;
      var cnt = 1;
      productFilter.artifactFilter.forEach(art =>{

        queryParams+=art;
        if(cnt<productFilter.artifactFilter.length)
        {
          queryParams+=",";
        }
        ++cnt;

      });
    }


    if(!argExists)
    {
      queryParams= "";
    }

    return queryParams;
  }
}
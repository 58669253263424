export enum ProductSubRoute {
  Tags = "tags",
  About = "about",
  Default= "",

}

export enum TagTabRoute {
  Manifest = "manifest",
  Content = "content",
  Packages = "packages",
  Referrers = "referrers",
  Default = "manifest",
}

export namespace TagTabRoute {
  export const itr = function* () {
    yield TagTabRoute.Manifest;
    yield TagTabRoute.Content;
    yield TagTabRoute.Packages;
    yield TagTabRoute.Referrers;
    yield TagTabRoute.Default;
  }
}

export namespace ProductSubRoute {
  export const itr = function* () {
    yield ProductSubRoute.About;
    yield ProductSubRoute.Tags;
    yield ProductSubRoute.Default;
  }
}
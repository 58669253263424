import {
  ActionButton,
  Link,
  MessageBar,
  MessageBarType,
  ProgressIndicator,
} from "@fluentui/react";
import { Tag } from "@models/Tag";
import { CatalogAPI } from "@utils/APIs/CatalogAPI";
import React, { useState } from "react";

interface SbomDownloadProps {
  // props
  tag: Tag;
}

export const SbomDownloadButton: React.FC<SbomDownloadProps> = ({ tag }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [shouldDisplayErrMsg, setErrMsgVisibility] = useState(false);
  const [ErrMsgText, setErrMsgText] = useState(DefaultErrMsgText);
  const [shouldDisplayProgressBar, setProgressBarVisbility] = useState(false);
  const [shouldDisplaySuccessMsg, setSuccessMsgVisibility] = useState(false);
  const sbomName = `${tag.repository}-${tag.name}.spdx.json`.replaceAll(
    "/",
    "_"
  );
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const closeErrMsg = React.useCallback(() => resetState(), []);
  const closeSuccessMsg = React.useCallback(() => resetState(), []);

  const resetState = () => {
    setProgress(undefined);
    setProgressBarVisbility(false);
    setErrMsgVisibility(false);
    setSuccessMsgVisibility(false);
    setErrMsgText(DefaultErrMsgText);
  };

  const downloadSbom = async () => {
    try {
      resetState();
      setIsDownloading(true);
      setProgressBarVisbility(true);
      console.time("Download Time");
      var response = await CatalogAPI.getArtifactData(
        tag.regHash,
        tag.repository,
        tag.layerZeroDigest
      );
      console.timeEnd("Download Time");
      console.time("Save Time");
      const total = tag.layerZeroSize ? tag.layerZeroSize : 0;
      var sbomData = response.body?.getReader();

      if (response.ok) {
        // Create a writable stream to hold the chunks
        const chunks: Uint8Array[] = [];
        let receivedLength = 0;
        // Read the stream
        if (sbomData) {
          let done = false;
          while (!done) {
            const { value, done: readerDone } = await sbomData.read();
            done = readerDone;
            if (value) {
              receivedLength += value.length;
              const realProgress = total ? receivedLength / total : 0;
              setProgress(realProgress);
              chunks.push(value);
            }
          }
        }

        // Concatenate all chunks into a single Uint8Array
        const totalLength = chunks.reduce(
          (acc, chunk) => acc + chunk.length,
          0
        );
        const concatenatedChunks = new Uint8Array(totalLength);
        let position = 0;
        for (const chunk of chunks) {
          concatenatedChunks.set(chunk, position);
          position += chunk.length;
        }
        // Create a temporary URL for the Blob
        const downloadUrl = URL.createObjectURL(
          new Blob([concatenatedChunks], { type: "application/spdx+json" })
        );

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = sbomName; // Set the filename for the download

        // Append the link to the body (not visible)
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
        console.timeEnd("Save Time");
        setSuccessMsgVisibility(true);
        setProgress(1);
      }
      // Unauthorized
      else if (response.status === 401) {
        setErrMsgText("You do not have permission to access this artifact.");
        setErrMsgVisibility(true);
        console.error(response.status + ":" + ErrMsgText);
      }
      // No Content or Not Found
      else if (response.status === 204 || response.status === 404) {
        setErrMsgText("Artifact was not found.");
        setErrMsgVisibility(true);
        console.error(response.status + ":" + ErrMsgText);
      }
      // Other errors
      else {
        setErrMsgVisibility(true);
        console.error(response.status + ":" + ErrMsgText);
      }
    } catch (error) {
      setErrMsgVisibility(true);
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <ActionButton
          text={isDownloading ? "Downloading SBOM..." : "Download SBOM"}
          title="Download SBOM"
          iconProps={{ iconName: "DownloadDocument" }}
          onClick={downloadSbom}
          disabled={isDownloading}
          styles={{
            root: {
              backgroundColor: "transparent",
              border: "none",
              padding: "0",
              height: "auto",
              minWidth: "unset",
              outline: "none",
              alignItems: "baseline",
            },
            rootHovered: {
              textDecoration: "underline",
              textDecorationColor: "#0067b8",
            },
            label: {
              color: "#0067b8",
              margin: "0",
              padding: "0",
              paddingTop: "4px",
            },
            icon: {
              color: "#0067b8",
              marginRight: "8px",
              cursor: "pointer",
            },
          }}
        />
        <div style={{ width: "150px" }}>
          <ProgressIndicator
            label=""
            progressHidden={!shouldDisplayProgressBar}
            percentComplete={progress}
          />
        </div>
        {shouldDisplayProgressBar && (
          <span style={{ marginLeft: "10px" }}>{`${
            progress != undefined ? (progress * 100).toFixed(0) : 0
          }%`}</span>
        )}
      </div>
      <div>
        {shouldDisplayErrMsg && (
          <ErrorMessageBar
            errMsgText={ErrMsgText}
            errMsgLink={ErrMsgLink}
            errMsgLinkText={ErrMsgLinkText}
            closeErrMsg={closeErrMsg}
          />
        )}
        {shouldDisplaySuccessMsg && (
          <SuccessMessageBar
            msgText={"SBOM " + sbomName + " was downloaded successfully."}
            closeSuccessMsg={closeSuccessMsg}
          />
        )}
      </div>
    </>
  );
};

interface ErrorMsgProps {
  errMsgText: string;
  errMsgLink: string;
  errMsgLinkText: string;
  closeErrMsg?: () => void;
}

const DefaultErrMsgText =
  "SBOM Download Failed. Artifact was not found or you do not have permission to access.";
const ErrMsgLink =
  "https://eng.ms/cid/c0f3ac52-9058-4e85-99dc-9c89dcfbf077/fid/94f3a58cfc1a6f1df985d6cd776fab47b921fb6599d8c7f1a49bb9d2ee041d9e";
const ErrMsgLinkText = "Visit MAR Engineering Hub for additional guidance.";

const ErrorMessageBar: React.FC<ErrorMsgProps> = ({
  errMsgText,
  errMsgLink,
  errMsgLinkText,
  closeErrMsg,
}) => (
  <MessageBar
    messageBarType={MessageBarType.error}
    isMultiline={false}
    dismissButtonAriaLabel="Close"
    onDismiss={closeErrMsg}
  >
    {errMsgText}
    <Link href={errMsgLink} target="_blank" underline>
      {errMsgLinkText}
    </Link>
  </MessageBar>
);

interface SuccessMsgProps {
  msgText: string;
  closeSuccessMsg?: () => void;
}

const SuccessMessageBar: React.FC<SuccessMsgProps> = ({
  msgText,
  closeSuccessMsg,
}) => (
  <MessageBar
    messageBarType={MessageBarType.success}
    isMultiline={false}
    dismissButtonAriaLabel="Close"
    onDismiss={closeSuccessMsg}
  >
    {msgText}
  </MessageBar>
);

export enum PaginationSegment {
  Start,
  Middle,
  End
}

export namespace PaginationSegment {
  export function toCssClass(seg: PaginationSegment) {
    let result: string;
    switch (seg) {
      case PaginationSegment.Start:
        result = 'pagination-segment-start'
        break;
      case PaginationSegment.Middle:
        result = 'pagination-segment-middle'
        break;
      case PaginationSegment.End:
        result = 'pagination-segment-end'
        break;
    }
    return result;
  }
}
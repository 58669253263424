import { NotFound } from "@components/Pages/NotFound";
import { PathUtils } from "@utils/General/PathUtils";
import React from "react";
import { Route, RouteComponentProps } from "react-router";

export interface IRepoRouteProps<T extends string> {
  render: (props: IRepoRouteComponentProps<T>) => React.ReactNode;
  renderBadEnding?: () => React.ReactNode;
  ending: T | T[];
  children?: never;
  path: string | string[];
}

interface IRepoRouteComponentProps<T> {
  routeProps: RouteComponentProps<{ repo: string }>;
  repo: string;
  regHash: string;
  ending: T;
}

type RepoPathString = `${string}/:repo+`;


export class RepoRoute<T extends string> extends React.Component<IRepoRouteProps<T>> {

  public getParts(repoStr: string, ending: T | T[] = this.props.ending): [string, string, T] | undefined {
    let result: [string, string, T] | undefined = undefined;
    repoStr = PathUtils.removeTrailingSlash(repoStr);
    if (typeof ending === 'string') {
      if (repoStr.endsWith(ending)) {
        let repo: string;
        if (ending.length === 0) {
          // if ending is empty (user doesn't provide subroute), line 27 already removed slash
          repo = repoStr;
        } else {
          //removes the ending + the preceeding slash
          repo = repoStr.substring(0, repoStr.length - 1 - ending.length);
        }
        let slashIdx = repo.indexOf('/');
        let reg = repo.substring(0, slashIdx);
        repo = repo.substring(slashIdx +1);
        result = [reg, repo, ending];
      }
    } else {
      for (let end of ending) {
        result = this.getParts(repoStr, end);
        //If we get a hit, end the loop
        if (result) {
          break;
        }
      }
    }
    return result;
  }

  public render() {
    //Localized switch can replace the path with an array of paths
    const path: RepoPathString | RepoPathString[] = typeof this.props.path === 'string' ? (`${this.props.path}/:repo+` as RepoPathString) : (this.props.path.map(basePath => `${basePath}/:repo+`) as RepoPathString[]);
    return (
      <Route exact path={path} render={props => {
        const parts = this.getParts(props.match.params.repo);
        if (parts) {
          return this.props.render({
            routeProps: props,
            regHash: parts[0],
            repo: parts[1],
            ending: parts[2]
          });
        } else {
          //page ending wasn't there, thus not found
          if (this.props.renderBadEnding) {
            return this.props.renderBadEnding();
          } else {
            return <NotFound />;
          }
        }
      }} />
    );
  }
};
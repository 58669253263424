/**
 * If you modify this file, make sure to run schema generation using npm package "typescript-json-schema"
 * Run from UI Directory
 * Command: typescript-json-schema ./tsconfig.json LanguagePack -o ./schemas/json/language_pack.schema.json --noExtraProps --required
 * Also add a @ts-ignore comment to the english lang pack so it doesn't error out. Remove when the schema is generated.
 */

import { Locale } from "./Locale";

import English_US_Pack from "@intl/Languages/mcr.en-us.json";
import { LocaleAPI } from "@utils/APIs/LocaleAPI";

export const EnglishUS: LanguagePack = English_US_Pack;

export interface LanguagePack {
  "$schema": string;
  locale_code: string;
  app_title: string;
  app_title_mobile: string;
  general: {
    previous: string;
    next: string;
    docker_pull_aria_label_template: string;
    greeting_template: string;
    sign_in: string;
    sign_out: string;
    search: string;
  }
  not_found_page: {
    heading: string;
    sub_message: string;
  }
  terms_of_use_page: {
    heading: string;
    last_update: string;
    terms: {
      heading: string;
      body: string;
    }
    services: {
      heading: string;
      body: string;
    }
    privacy: {
      heading: string;
      body: string;
    }
    artifacts: {
      heading: string;
      body: string;
    }
    artifacts_legal: {
      heading:string;
      body: string;
      body_one: string;
      link: string;
      body_two: string;
    }
    docker: {
      heading: string;
      body: string;
      body_one: string;
      body_a_bold: string;
      body_a: string;
      body_i_bold: string;
      body_i: string;
      body_ii_bold: string;
      body_ii: string;
      body_iii_bold: string;
      body_iii: string;
      body_iv_bold: string;
      body_iv: string;
      body_v_bold: string;
      body_v: string;
      body_b_bold: string;
      body_b: string;
      body_c_bold: string;
      body_c: string;
    }
    conduct: {
      heading: string;
      body: string;
      body_one: string;
      body_two: string;
      body_three: string;
      body_four: string;
      body_five: string;
      body_six: string;
      body_seven: string;
      body_eight: string;
      body_nine: string;
    }
    third_party: {
      heading: string;
      body: string;
    }
    copyright: {
      heading: string;
      body: string;
      link: string;
      body_one: string;
    }
    termination: {
      heading: string;
      body: string;
    }
    export: {
      heading: string;
      body: string;
      link: string;
      body_one: string;
    }
    unsolicated: {
      heading: string;
      body: string;
    }
    warranties: {
      heading: string;
      body: string;
    }
    liability: {
      heading: string;
      body: string;
    }
    entity: {
      heading: string;
      body: string;
    }
  }
  catalog_page: {
    banner_message: {
      default: string;
      post_search: string;
      unlisted_title: string;
      sbom_disc_start: string;
      sbom_disc_end: string;
      art_sync_start: string;
      art_sync_end: string;
    }
    clear_search_button: string;
    filter_area_heading: string;
    no_filters_available: string;
    pagination_control_aria_label: string;
    errors: {
      load_failed: string;
      no_products: string;
      no_results: string;
    }
    product_card: {
      learn_more: string;
      learn_more_aria_label: string;
      no_description: string;
    }
    page_query_param: string;
    search_query_param: string;
  };
  product_page: {
    errors: {
      load_failed: string;
      tag_load_failed: string;
      no_readme: string;
      no_pull_count: string;
    }
    metadata: {
      headers: {
        last_pushed: string;
        categories: string;
        contact_info: string;
        help: string;
        project: string;
        total_pull_count: string;
      }
      project: {
        website: string;
        repository: string;
        license: string;
      }
      help: {
        documentation: string;
        support: string;
      }
    }
    tab_controls: {
      aria_label: string;
      about: string;
      tags: string;
    }
    back_to_catalog: string;
    last_pushed: string;
    tag_table: {
      headers: {
        name: string;
        digest: string;
        artifactType: string;
        lastModifiedDate: string;
        os: string;
        arch: string;
      };
      digest_label: string;
      filter_label: string;
      filter_aria_label: string;
      filter_placeholder: string;
      supported_tags_label: string;
    }
  }
}

export class LanguagePackLoader {
  public static async load(locale: Locale): Promise<LanguagePack> {
    let result = EnglishUS;
    if (locale === Locale.English_US) {
      return EnglishUS;
    } else {
      try {
        //If this call fails, we no-op so that English US will be returned.
        result = await LocaleAPI.getLanguagePack(locale);
      } catch {
        //No-OP
      }
    }
    return result;
  }
}
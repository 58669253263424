import { LinkButton } from "@components/LinkButton";
import React from "react";
import { DOMUtils } from "../../../Utilities/DOMUtils";

export interface IDropdownOption {
  label: string;
  action: () => void;
}

interface IDropdownProps {
  label: string;
  options: IDropdownOption[];
  className?: string;
  ariaLabel?: string;
  direction?: "up" | "down" | "left" | "right";
  reflow?: boolean;
  buttonClasses?: string[];
}

export class Dropdown extends React.Component<IDropdownProps> {

  private readonly buttonId: string;
  private readonly dropdownId: string;
  private dropdownObj: mwf.Dropdown | undefined;
  private readonly dropdownRef: React.RefObject<HTMLUListElement>
  private readonly dropdownButtonRef: React.RefObject<HTMLButtonElement>;

  constructor(props: IDropdownProps) {
    super(props);
    this.buttonId = DOMUtils.generateDOMUuid();
    this.dropdownId = DOMUtils.generateDOMUuid();
    this.dropdownRef = React.createRef();
    this.dropdownButtonRef = React.createRef();
  }

  componentDidMount() {
    if (this.dropdownButtonRef.current) {
      this.dropdownObj = new mwf.Dropdown({
        el: this.dropdownButtonRef.current,
        enableReflow: !!this.props.reflow
      });
    }
  }

  componentWillUnmount() {
    this.dropdownObj?.remove();
  }

  public focus() {
    this.dropdownButtonRef.current?.focus();
  }

  public blur() {
    this.dropdownButtonRef.current?.blur();
  }

  public click() {
    this.dropdownButtonRef.current?.click();
  }

  private renderOptions() {
    return this.props.options.map((option) => (
      <li key={option.label}>
        <LinkButton className="dropdown-item" onClick={() => {
          //@ts-ignore
          this.dropdownObj?.toggle();
          option.action();
        }}>{option.label}</LinkButton>
      </li>
    ));
  }

  public render() {

    return (
      <div className={`drop${this.props.direction ?? 'down'}`}>
        <button
          aria-label={this.props.ariaLabel}
          ref={this.dropdownButtonRef}
          className={`btn btn-faint-secondary dropdown-toggle ${this.props.buttonClasses?.join(" ") ?? ""}`}
          id={this.buttonId}
          aria-expanded="false"
          aria-controls={this.dropdownId} type="button"
        >
          {this.props.label}
        </button>
        <ul ref={this.dropdownRef} className="dropdown-menu" id={this.dropdownId} aria-labelledby={this.buttonId}>
          {this.renderOptions()}
        </ul>
      </div>
    )
  }
}
export namespace ClipBoard {
  /**
   * This function copies text to the clipboard.
   * @param text the text to copy
   * @returns a boolean if the data was copied to the clipboard.
   */
  export async function copy(text: string): Promise<boolean> {
    /**
     * Implementation Notes:
     * For whatever reason document.execCommand was deprecated and might get removed from browsers.
     * The Clipboard API however is still very new and most old browsers do not support it. 
     * As such we will first try to see if navigator has the clipboard API
     * If not we will check for document.execCommand.
     * 
     * It is unlikely that any browser vendor would not have one of these implemented. 
     */
    let result = false;
    if (navigator?.clipboard !== undefined) {
      try {
        await navigator.clipboard.writeText(text);
        result = true;
      } catch {
        result = false;
      }
    } else if (document.execCommand) {
      let tempInput = document.createElement('input');
      document.body.appendChild(tempInput);
      tempInput.value = text;
      tempInput.select();
      result = document.execCommand('copy');
      tempInput.remove();
    }
    return result;
  }
}
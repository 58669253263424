//This component exists to generate markdown in a common format. If something more complex is needed, use the Library directly

import { IconButton } from "@fluentui/react";
import { ClipBoard } from "@utils/General/ClipBoard";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { HeadingProps, OrderedListProps, ReactMarkdownProps } from "react-markdown/lib/ast-to-react";
import remarkGfm from "remark-gfm";
import markdownStyles from "./Markdown.module.css";

interface IMarkdownProps {
  children: string;
}

type TableProps = React.PropsWithChildren<Pick<React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement>, "key" | keyof React.TableHTMLAttributes<HTMLTableElement>> & ReactMarkdownProps>

export const Markdown: React.FC<IMarkdownProps> = (props) => {

  useEffect(() => {
    const id = window.location.hash.substring(1);
    if (id) {
      let elem = document.getElementById(id);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scroll(0, 0);
    }
  }, []);

  const orderedListHandler = (params: React.PropsWithChildren<OrderedListProps>) => {
    const { node, ...olProps } = params;
    let olType: "1" | "a" | "i" | "A" | "I" | undefined;
    if (olProps.depth % 3 === 0) {
      olType = '1';
    } else if (olProps.depth % 3 === 1) {
      olType = 'a';
    } else {
      olType = 'i';
    }

    return (
      <ol type={olType}>
        {olProps.children}
      </ol>
    )
  }

  const tableHandler = (params: TableProps) => {
    const { node, ...tableProps } = params;
    return (
      <table className="table table-striped table-sm">
        {tableProps.children}
      </table>
    );
  }

  const headingHandler = (params: React.PropsWithChildren<HeadingProps>, type: "h1" | "h2") => {
    const { node, ...props } = params;
    let id: string | undefined = undefined;
    if (node.children.length === 1 && node.children[0].type === 'text') {
      id = node.children[0].value.toLowerCase().replace(/\s/g, "_");
    }
    if (id) {
      return (
        <div className={markdownStyles["heading-container"]}>
          {React.createElement(type, { className: `area-heading ${markdownStyles.heading}`, id: id }, (
            <a href={`#${id}`} onClick={(event) => {
              event.preventDefault();
              const targetElem = document.getElementById(id!)
              targetElem?.scrollIntoView({ behavior: "smooth" });
              window.location.hash = `#${id}`
            }}>{props.children}</a>
          ))}
          <IconButton
            iconProps={{ iconName: "Link" }}
            className={markdownStyles["heading-link"]}
            onClick={() => {
              const path = window.location.href.replace(window.location.hash, "");
              ClipBoard.copy(`${path}#${id}`);
            }}
            ariaLabel="Copy Link"
          />
        </div>
      );
    } else {
      //TODO: Investigate if we should pass all props or not. Currently I don't think markdown adds anything.
      return React.createElement(type, { className: "area-heading" }, props.children) //<h? className="area-heading">{props.children}</h?>
    }
  };

  function render() {
    return (
      <ReactMarkdown
        components={{
          ol: orderedListHandler,
          table: tableHandler,
          h1: (params) => headingHandler(params, "h1"),
          h2: (params) => headingHandler(params, "h2"),
        }}
        remarkPlugins={[remarkGfm]}
      >
        {props.children}
      </ReactMarkdown>
    )
  }

  return render();
}
export namespace PathUtils {
  export function removeTrailingSlash(path: string) {
    return (path.endsWith('/') ? path.substring(0, path.length - 1) : path);
  }
  export function removeLeadingSlash(path: string) {
    return (path.startsWith('/') ? path.substring(1) : path);
  }
  /**
   * Gets each section of the url path as an array. Ignores leading and trailing slashes.
   */
  export function getSections(path: string) {
    return removeLeadingSlash(removeTrailingSlash(path)).split("/");
  }
}
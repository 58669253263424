import { LocaleAPI } from "@utils/APIs/LocaleAPI";

export enum Locale {
  English_US = "en-us",
  English_GB = "en-gb",
  Chinese_Simplified = "zh-cn",
  LEET_SPEAK = "1337"
}

const NativeNames = new Map();

export namespace Locale {

  export const BrowserIntlSupported = !(window["Intl"] === undefined || window.Intl["DisplayNames"] === undefined || window.Intl["Locale"] === undefined);
  
  export const itr = function* () {
    yield Locale.English_US;
    yield Locale.English_GB;
    yield Locale.Chinese_Simplified;
    yield Locale.LEET_SPEAK;
  }
  export function toNativeName(locale: Locale) {
    //Separate this from eerything else so it can be deleted later.
    if (locale === Locale.LEET_SPEAK) {
      return "L337 5p34k";
    }
    let result: string = locale;
    if (BrowserIntlSupported) {
      const region = new Intl.DisplayNames(locale, { type: 'region' });
      const lang = new Intl.DisplayNames(locale, { type: "language" });
      const jsLocale = new Intl.Locale(locale);
      if (jsLocale.region && jsLocale.language) {
        result = `${lang.of(jsLocale.language)}(${region.of(jsLocale.region)})`
      }
    } else {
      const name = NativeNames.get(locale);
      if (name) {
        result = name;
      }
    }
    return result;
  }
  export function fromString(strValue: string): Locale | undefined {
    let result = undefined;
    strValue = strValue.toLowerCase();
    switch (strValue) {
      case Locale.English_US:
        result = Locale.English_US;
        break;
      case Locale.English_GB:
        result = Locale.English_GB;
        break;
      case Locale.Chinese_Simplified:
        result = Locale.Chinese_Simplified
        break;
      case Locale.LEET_SPEAK:
        result = Locale.LEET_SPEAK;
        break;
    }
    return result;
  }
  export function fromCurrentLocation(): Locale | undefined
  export function fromCurrentLocation(defaultLocale: Locale): Locale
  export function fromCurrentLocation(defaultLocale?: Locale) {
    let path = window.location.pathname;
    if (path.startsWith("/")) {
      path = path.substring(1);
    }
    const parts = path.split("/");
    const potentialLocale = parts[0];
    return Locale.fromString(potentialLocale) ?? defaultLocale;
  }
  /**
   * This function allows us to fallback if certain parts on the Intl API are not available.
   */
  export async function initNativeNames() {
    if (!BrowserIntlSupported) {
      const data = await LocaleAPI.getSupportedNativeNames();
      for (const locale in data) {
        const name = data[locale];
        NativeNames.set(locale, name);
      }
    }
  }
}
import React from "react";
import { autobind } from "@utils/Decorators";
import { DOMUtils } from "@utils/DOMUtils";

/**
 * I implemented these classes using inheritance as an example of how it can be useful
 * It should be noted that doing it this way can cause some headaches with Types.
 * I would generally not recommend doing it this way unless the components share a 
 * Large volume of functions that require generics.
 */


/** */
interface IInputProps<T> {
  inline?: boolean;
  inlineWidth?: number | string;
  labelText?: string;
  customId?: string;
  onChange?: (newValue: T, oldValue: T) => void;
  onKeyPressed?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  readonly?: boolean;
  invalid?: boolean;
  ariaLabel?: string;
  placeholder?: string;
}

interface IInputState<T> {
  value: T
}

abstract class InternalInput<P extends IInputProps<T>, T> extends React.Component<P, IInputState<T>>
{
  private inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: P) {
    super(props);
    this.inputRef = React.createRef();
  }

  @autobind
  protected onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let value = this.processValue(event.target.value);
    if (this.props.onChange) {
      this.props.onChange(value, this.state.value);
    }
    this.setState({
      value: value
    });
  }

  public focus() {
    this.inputRef.current?.focus();
  }

  public abstract reset(): void;
  protected abstract processValue(val: string): T;
  protected abstract get type(): React.HTMLInputTypeAttribute;

  public render() {
    const id = this.props.customId ?? DOMUtils.generateDOMUuid();
    let control = (
      <>
        <label htmlFor={id} className={this.props.inline ? "mr-sm-2" : ""}>{this.props.labelText}</label>
        <input
          ref={this.inputRef}
          className={`form-control ${this.props.invalid ? "is-invalid" : ""}`}
          type={this.type}
          id={id}
          value={`${this.state.value}`}
          onChange={this.onChange}
          disabled={this.props.disabled}
          readOnly={this.props.readonly}
          aria-label={this.props.ariaLabel}
          placeholder={this.props.placeholder}
          onKeyPress={this.props.onKeyPressed}
          style={{ width: this.props.inlineWidth }}
        ></input>
      </>
    );
    if (this.props.inline) {
      control = (
        <div className="form-inline">
          {control}
        </div>
      )
    }
    return control;
  }
}

//TEXT INPUT

interface ITextInputProps extends IInputProps<string> {
  type?: "text" | "email" | "password";
}

export class Input extends InternalInput<ITextInputProps, string> {

  constructor(props: ITextInputProps) {
    super(props);
    this.state = {
      value: ""
    }
  }

  public reset(): void {
    this.setState({
      value: ""
    });
  }

  public setValue(val: string) {
    this.setState({
      value: val
    });
  }

  protected processValue(val: string): string {
    return val;
  }

  protected get type() {
    return this.props.type ?? "";
  }
}

//NUMBER INPUT

interface INumberInputProps extends IInputProps<number | undefined> {
  onChange?: (newValue: number | undefined, oldValue: number | undefined) => void;
}

export class NumberInput extends InternalInput<INumberInputProps, number | undefined> {

  constructor(props: INumberInputProps) {
    super(props);
    this.state = {
      value: undefined
    }
  }

  public reset(): void {
    this.setState({
      value: undefined
    });
  }

  protected processValue(strValue: string) {
    return strValue === '' ? undefined : Number(strValue);
  }

  protected get type() {
    return "number";
  }
}
import React from "react";
import pageStyles from "./ProductPage.module.css";

export type DefaultDisclaimer = {
  type: "default";
  message: string;
  show: boolean;
};

export type MultiPartDisclaimer = {
  type: "multi-part";
  startMessage: string;
  hyperlink: string;
  hyperlinkText: string;
  endMessage: string;
  linkColor: string;
  show: boolean;
};

export type Disclaimer = DefaultDisclaimer | MultiPartDisclaimer;

export type DisclaimerListProps = {
  disclaimers: Disclaimer[];
};

function hasVisibleDisclaimers(disclaimers: Disclaimer[]): boolean {
  for (const disclaimer of disclaimers) {
    if (disclaimer.show) {
      return true;
    }
  }
  return false;
}

const DisclaimerList: React.FC<DisclaimerListProps> = ({ disclaimers }) => {
  if (!hasVisibleDisclaimers(disclaimers)) {
    return null;
  } else {
    return (
      <div className={`${pageStyles["disclaimer-container"]}`}>
        {disclaimers
          .filter((disclaimer: { show: boolean }) => disclaimer.show)
          .map((disclaimer, index) => {
            if (disclaimer.type === "multi-part") {
              return (
                <div key={index} className={`${pageStyles["disclaimer-box"]}`}>
                  {disclaimer.startMessage}{" "}
                  <a
                    href={disclaimer.hyperlink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: disclaimer.linkColor }}
                  >
                    {disclaimer.hyperlinkText}
                  </a>
                  {disclaimer.endMessage}
                </div>
              );
            } else if (disclaimer.type === "default") {
              return (
                <div key={index} className={`${pageStyles["disclaimer-box"]}`}>
                  <p>{disclaimer.message}</p>
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
    );
  }
};

export default DisclaimerList;

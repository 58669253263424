import React from "react";
import { LinkButton } from "@components/LinkButton";
import { PaginationSegment } from "./PaginationSegment";

import paginationStyles from "./PaginationControl.module.css";
import { Debug } from "@utils/Debug/Debug";
import { AppContext } from "@utils/AppContext";

interface IPaginationControlProps {
  currentPage: number;
  totalPages: number;
  onPageChanged: (newPage: number, oldPage: number) => void;
  ariaLabel: string;
  className?: string;
  noButtonText?: boolean;
  justification?: 'left' | 'right' | 'center';
  getPageUrl?: (page: number) => string;
}

export class PaginationControl extends React.Component<IPaginationControlProps> {

  public static contextType = AppContext;
  public context!: React.ContextType<typeof AppContext>;

  private determineSegment(): PaginationSegment | undefined {
    let result: PaginationSegment | undefined;
    if (this.props.totalPages >= 7) {
      //Start
      if (this.props.currentPage <= 5) {
        result = PaginationSegment.Start;
      }
      //End
      else if ((this.props.currentPage + 5) >= this.props.totalPages) {
        result = PaginationSegment.End;
      }
      //Middle
      else {
        result = PaginationSegment.Middle;
      }
    }
    return result;
  }

  private generatePageNumber(page: number) {
    const active = this.props.currentPage === page;
    return (
      <li key={`page_${page}`} className={["page-item", active ? "active" : ""].join(' ')} aria-current={active ? "page" : undefined}>
        <LinkButton href={this.props.getPageUrl?.(page)} className="page-link" onClick={(event) => this.clickPage(page, event)}>
          <span>{page}</span>
        </LinkButton>
      </li>
    );
  }

  private generatePagePlaceholder(number = 1) {
    return (
      <li key={`placeholder_${number}`} className="page-item disabled ellipsis">
        <span className="page-link text-body">...</span>
      </li>
    );
  }

  private generatePageNumbers(segment?: PaginationSegment): JSX.Element[] {
    const results: JSX.Element[] = [];

    if (segment !== undefined) {
      switch (segment) {
        case PaginationSegment.Start:
          //Push the start numbers
          for (let i = 1; i <= 5; i++) {
            results.push(this.generatePageNumber(i));
          }
          //Push the placeholder
          results.push(this.generatePagePlaceholder());
          //Push the final number
          results.push(this.generatePageNumber(this.props.totalPages));
          break;
        case PaginationSegment.Middle:
          //Push the first number
          results.push(this.generatePageNumber(1));
          //Push a placeholder
          results.push(this.generatePagePlaceholder(1));
          //push the middle pages
          results.push(this.generatePageNumber(this.props.currentPage - 1));
          results.push(this.generatePageNumber(this.props.currentPage));
          results.push(this.generatePageNumber(this.props.currentPage + 1));
          //Push another placeholder
          results.push(this.generatePagePlaceholder(2));
          //Push the final number
          results.push(this.generatePageNumber(this.props.totalPages));
          break;
        case PaginationSegment.End:
          //Push the first number
          results.push(this.generatePageNumber(1));
          //Push the placeholder
          results.push(this.generatePagePlaceholder());
          //Push the start numbers
          const totalPages = this.props.totalPages;
          for (let i = (totalPages - 5); i <= totalPages; i++) {
            results.push(this.generatePageNumber(i));
          }
          break;
      }
    } else {
      for (let i = 1; i <= this.props.totalPages; i++) {
        results.push(this.generatePageNumber(i));
      }
    }
    return results;
  }

  private previous(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    this.gotoPage(this.props.currentPage - 1);
  }

  private next(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    this.gotoPage(this.props.currentPage + 1);
  }

  private clickPage(page: number, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    this.gotoPage(page);
  }

  private gotoPage(num: number) {
    if (num < 1 || num > this.props.totalPages) {
      //Only do a warning because for some reason, the enter button can get past the disabled button.
      Debug.warn("You can't go to a page that does not exist.");
    } else {
      this.props.onPageChanged(num, this.props.currentPage);
    }
  }

  private createNoTextElement() {
    return <div style={{ maxWidth: "0px", overflow: 'hidden' }}>|</div>
  }

  render() {

    const segment = this.determineSegment();
    const segmentCssClass = segment ? PaginationSegment.toCssClass(segment) : "";

    const previousDisabled = this.props.currentPage === 1;
    const nextDisabled = this.props.currentPage === this.props.totalPages;

    let justificationClass = "";
    if (this.props.justification === 'left') {
      justificationClass = paginationStyles["flex-start"]
    } else if (this.props.justification === 'right') {
      justificationClass = paginationStyles["flex-end"]
    }

    return (
      <nav aria-label={this.props.ariaLabel} className={[paginationStyles["pagination-control"], this.props.className ?? "", justificationClass].join(" ")}>
        <ul className={[segmentCssClass, "pagination"].join(" ")}>
          <li className={["page-item", previousDisabled ? "disabled" : ""].join(' ')}>
            <LinkButton 
              href={this.props.getPageUrl?.(this.props.currentPage - 1)} 
              className="page-link" 
              onClick={(event) => this.previous(event)}
            >
              {this.props.noButtonText ? this.createNoTextElement() : this.context.languagePack.general.previous}
            </LinkButton>
          </li>
          {this.generatePageNumbers(segment)}
          <li className={["page-item", nextDisabled ? "disabled" : ""].join(' ')}>
            <LinkButton 
              href={this.props.getPageUrl?.(this.props.currentPage + 1)}
              className="page-link" 
              onClick={(event) => this.next(event)}
            >
              {this.props.noButtonText ? this.createNoTextElement() : this.context.languagePack.general.next}
            </LinkButton>
          </li>
        </ul>
      </nav>
    );
  }
}
import { DeveloperError } from "@errors/DeveloperError";
import { Environment } from "./Environment";

/**
 * autobind decorator automatically binds a function to the enclosing class. It is a shortcut for doing `this.func = this.func.bind(this)` in the constructor
 */
export function autobind<T extends Function>(target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> | void {

  if (!descriptor || typeof descriptor.value !== 'function' || !target) {
    throw new DeveloperError("autobind decorator can only be used with class memeber functions");
  }

  return {
    configurable: true,
    get(this: T): T {
      const boundFunc = descriptor.value!.bind(this);
      Object.defineProperty(this, propertyKey, {
        value: boundFunc,
        configurable: true,
        writable: true
      });
      return boundFunc;
    }
  }
}

/**
 * This decorator will make it so code only runs in a development environment, in other environments a decorated function will do nothing.
 * Usage on class: If a class is decorated, all it's member functions will only run in dev.
 * Usage on class function: If a member function is decorated, it will only run in dev.
 */
export function devOnly<T extends { new(...args: any[]): {} }>(ctor: T): void;
export function devOnly<T extends Function>(target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> | void;
export function devOnly(...args: any[]) {
  //Check the args length. If it is on a class, there will only be one, if a member function, it will be 3.
  if (args.length === 1) {
    const ctor: { new(...args: any[]): {} } = args[0];
    //Check to see if there is a prototype on the constructor, if not then we may be trying to decorate something else and we should throw an error.
    if (ctor.prototype) {
      //This gets the function descriptors for the prototype. They are a detailed explaination of a function. Check the TypedPropertyDescriptor type for more info.
      let descriptors = Object.entries(Object.getOwnPropertyDescriptors(ctor.prototype));
      for (let [propertyKey, descriptor] of descriptors) {
        //For each of the functions, redefine them to the same thing, but do a isDevelopment check first.
        Object.defineProperty(ctor.prototype, propertyKey, {
          value: function (...args: any[]) {
            if (Environment.isDevelopment && descriptor.value) {
              descriptor.value(...args);
            }
          }
        });
      }
    } else {
      throw new DeveloperError("devOnly decorator can only be used on classes and class member functions.");
    }
    //We are working with a member function because there are 3 arguments.
  } else if (args.length === 3) {
    let [target, propertyKey, descriptor] = args as [object, string, TypedPropertyDescriptor<any>];

    if (!descriptor || typeof descriptor.value !== 'function' || !target) {
      throw new DeveloperError("devOnly decorator can only be used on classes and class member functions.");
    }

    return {
      configurable: true,
      get(this) {
        //Wrap the function so that we do a check for development mode.
        const wrappedFunc = function (...args: any[]) {
          if (Environment.isDevelopment && descriptor.value) {
            descriptor.value(...args);
          }
        }
        //Overwrite the existing function with the old one.
        Object.defineProperty(this, propertyKey, {
          value: wrappedFunc,
          configurable: true,
          writable: true
        });
        return wrappedFunc;
      }
    }
  } else {
    throw new DeveloperError("devOnly decorator can only be used on classes and class member functions.")
  }
}


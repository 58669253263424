import React from "react";
import { DeveloperError } from "@errors/DeveloperError";
import { LinkButton } from "../LinkButton";

interface IBannerButton {
  link?: string,
  text: string,
  ariaLabel: string,
  action?: React.MouseEventHandler<HTMLAnchorElement>;
}

interface IBannerProps {
  primaryButton?: IBannerButton;
  secondaryButton?: IBannerButton;
  icon?: {
    href: string,
    shape: 'round' | 'square'
  };
  content: {
    quote?: {
      text: string,
      source: string
    };
    paragraph?: {
      badge?: string,
      title: string,
      text: string
    };
    render?: () => JSX.Element;
  };
  border?: boolean;
  shaded?: boolean;
  darkShaded?: boolean;
}

export class Banner extends React.Component<IBannerProps> {

  private renderImage() {
    let result: JSX.Element | null = null;
    if (this.props.icon) {
      const imageClasses = ["h-auto"];
      if (this.props.icon.shape === 'round') {
        imageClasses.push('rounded-circle');
      }

      result = (
        <div className="w-auto pt-4 pl-4 py-md-5 pl-md-5 pr-md-g">
          <img src={this.props.icon.href} className={imageClasses.join(' ')} alt="" width="64" />
        </div>
      )
    }
    return result;
  }

  private renderContent(): JSX.Element {
    const quote = this.props.content.quote;
    const paragraph = this.props.content.paragraph;
    const render = this.props.content.render;
    let result: JSX.Element;
    if (paragraph) {
      result = (
        <>
          {paragraph.badge && <span className="badge bg-yellow mb-1">{paragraph.badge}</span>}
          <h1 className="h3 ">{paragraph.title}</h1>

          <div className="mb-4">
            <p>{paragraph.text}</p>
          </div>
        </>
      )
    } else if (quote) {
      result = (
        <blockquote className="">
          <p className="h3">{quote.text}</p>
          <footer className="blockquote-footer">
            <cite>{quote.source}</cite>
          </footer>
        </blockquote>
      )
    } else if (render) {
      result = render();
    } else {
      throw new DeveloperError("One of the content types must be specified.");
    }
    return result;
  }

  private renderLinkGroup(): JSX.Element | null {
    let result = null;
    const { primaryButton, secondaryButton } = this.props;
    if (primaryButton || secondaryButton) {
      //Set the button class based on if the banner background is set
      let primaryClass = 'btn-primary';
      let secondaryClass = 'btn-outline-primary';
      if (this.props.shaded) {
        primaryClass = 'btn-inverted-brand';
        secondaryClass = 'btn-outline-light';
      } else if (this.props.darkShaded) {
        primaryClass = 'btn-brand-light';
        secondaryClass = 'btn-outline-brand-light';
      }
      result = (
        <div className="link-group">
          {primaryButton && <LinkButton onClick={primaryButton.action} href={primaryButton.link} className={`btn ${primaryClass}`} aria-label={primaryButton.ariaLabel}>{primaryButton.text}</LinkButton>}
          {secondaryButton && <LinkButton onClick={secondaryButton.action} href={secondaryButton.link} className={`btn ${secondaryClass}`} aria-label={secondaryButton.ariaLabel}>{secondaryButton.text}</LinkButton>}
        </div>
      );
    }
    return result;
  }

  render() {
    let borderClass = 'material-surface';
    if (this.props.border) {
      borderClass = 'border';
    } else if (this.props.shaded) {
      borderClass = 'material-color-brand';
    } else if (this.props.darkShaded) {
      borderClass = ' material-color-brand-dark';
    }

    return (
      <div className="card mx-ng mx-md-0">
        <div className={`row no-gutters ${borderClass}`}>
          {this.renderImage()}
          <div className="col-sm">
            <div className="card-body p-4 p-md-5 w-lg-col-11 w-xl-col-8 pl-md-g">
              {this.renderContent()}
              {this.renderLinkGroup()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
import React from "react";
import { autobind } from "@utils/Decorators";
import { Checkbox } from "@components/MWF/FormComponents/Checkbox";

interface ICatalogCheckboxFilterProps {
  label: string;
  onFilterApplied: () => void;
  onFilterRemoved: () => void;
  defaultChecked?: boolean;
  filterApplied?: boolean;
}

export class CatalogCheckboxFilter extends React.Component<ICatalogCheckboxFilterProps> {

  @autobind
  private onChange(checked: boolean) {
    if (checked) {
      this.props.onFilterApplied();
    } else {
      this.props.onFilterRemoved();
    }
  }

  public render() {
    return (
      <Checkbox label={this.props.label} defaultChecked={this.props.defaultChecked} onChange={this.onChange} checked={this.props.filterApplied} />
    );
  }

}
interface IButtonProps {
  theme?: "primary" | "primary-alt" | "secondary" | "inverse" | "link" | "brand" | "light" | "dark" | "brand-light" | "brand-dark";
  modifier?: "inverted" | "outline"
  block?: boolean;
  size?: "large" | "normal" | "small";
  disabled?: boolean;
  ariaLabel?: string;
  inactive?: boolean;
  faint?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const Button: React.FC<IButtonProps> = (props) => {

  const classNames = ["btn"];
  if (props.className) {
    classNames.push(props.className);
  }
  if (props.theme || props.modifier) {

    const theme = props.theme ?? "primary";

    let buttonClass = "";
    if (theme === 'link') {
      buttonClass = "btn-link";
    } else {
      buttonClass = `btn-${props.modifier ? props.modifier + "-" : ""}${theme}`
    }
    classNames.push(buttonClass);
  }

  if (props.block) {
    classNames.push("btn-block");
  }
  if (props.inactive) {
    classNames.push("inactive");
  }
  if (props.size) {
    switch (props.size) {
      case "large":
        classNames.push("btn-lg");
        break;
      case "small":
        classNames.push("btn-sm");
        break;
    }
  }

  function render() {
    return (
      <button type="button" onClick={props.onClick} disabled={props.disabled} className={classNames.join(" ")} aria-label={props.ariaLabel}>{props.children}</button>
    );
  }

  return render();
}
import { Locale } from "@intl/Locale";


export enum AppRoute {
  Samples = '/samples',
  Catalog = '/catalog',
  Root = '/',
  Reliability = '/reliability',
  TermsOfUse = '/terms-of-use',
  Product = '/product',
  Artifact = '/artifact',
  NotFound = '/notfound'
}

export namespace AppRoute {
  export const itr = function* () {
    yield AppRoute.Root;
    yield AppRoute.Catalog;
    yield AppRoute.Artifact;
    yield AppRoute.Product;
    yield AppRoute.Reliability
    yield AppRoute.NotFound;
    yield AppRoute.TermsOfUse;
    yield AppRoute.Samples;
  }

  export function getRoutes(locale: Locale): { [key in AppRoute]: string | string[] } {
    const result: { [key in AppRoute]: string | string[] } = {
      '/': `/${locale}`,
      '/catalog': `/${locale}/catalog`,
      '/artifact': `/${locale}/artifact`,
      '/product': `/${locale}/product`,
      '/notfound': `/${locale}/notfound`,
      '/terms-of-use': `/${locale}/terms-of-use`,
      '/reliability': `/${locale}/reliability`,
      '/samples': `/${locale}/samples`
    };
    if (locale === Locale.English_US) {
      for (let key in result) {
        //We know that key is an AppRoute
        const routeKey = key as AppRoute;
        //we know that the value can only be a string at this point
        const oldValue: string = result[routeKey] as string;
        const newValue = [key, oldValue];
        result[routeKey] = newValue;
      }
    }
    return result;
  }
  /**
   * Gets the route to pass to history push
   * @param route the base route
   * @param locale The locale to use
   * @param extension an extension to the URL, the base path is garunteeed to not have a trailing slash
   */
  export function getRoute(route: AppRoute, locale: Locale, extension: string = "") {
    return `/${locale}${route}${extension}`
  }

}


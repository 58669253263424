import { autobind } from "@utils/Decorators";
import React from "react";
import * as uuid from 'uuid';

interface ICollapseProps {
  buttonText: string;
  startOpen?: boolean;
  onHideStart?: EventListenerOrEventListenerObject
  onHideDone?: EventListenerOrEventListenerObject
  onShowStart?: EventListenerOrEventListenerObject
  onShowDone?: EventListenerOrEventListenerObject
  onRemove?: EventListenerOrEventListenerObject
  buttonSize?: ButtonSize
}

interface ICollapseState {
  collapsed: boolean;
}

type ButtonSize = 'small' | 'normal' | 'large';

enum CollapseEventNames {
  OnHideStart = 'onHide',
  OnHideDone = 'onHidden',
  OnShowStart = 'onShow',
  OnShowDone = 'onShown',
  OnUpdate = 'onUpdate', //According to the docs, this doesn't do much yet
  OnRemove = 'onRemove'
}


export class Collapse extends React.Component<ICollapseProps, ICollapseState> {

  private componentUuid: string;
  private buttonRef: React.RefObject<HTMLButtonElement>;
  private collapseObj: mwf.Collapse | undefined;

  constructor(props: ICollapseProps) {
    super(props);
    this.componentUuid = `collapse_${uuid.v4()}`;
    this.buttonRef = React.createRef<HTMLButtonElement>();
    this.state = {
      collapsed: !this.props.startOpen
    }
  }

  private setupEventListeners() {
    let contentElement: HTMLElement | undefined = this.collapseObj?.el;
    if (contentElement) {

      //Must have listeners
      contentElement.addEventListener(CollapseEventNames.OnHideStart, this.onHideStart);
      contentElement.addEventListener(CollapseEventNames.OnShowStart, this.onShowStart);

      if (this.props.onHideStart) {
        contentElement.addEventListener(CollapseEventNames.OnHideStart, this.props.onHideStart)
      }
      if (this.props.onHideDone) {
        contentElement.addEventListener(CollapseEventNames.OnHideDone, this.props.onHideDone)
      }
      if (this.props.onShowStart) {
        contentElement.addEventListener(CollapseEventNames.OnShowStart, this.props.onShowStart)
      }
      if (this.props.onShowDone) {
        contentElement.addEventListener(CollapseEventNames.OnShowDone, this.props.onShowDone)
      }
      if (this.props.onRemove) {
        contentElement.addEventListener(CollapseEventNames.OnRemove, this.props.onRemove)
      }
    }
  }

  private removeEventListeners() {
    let contentElement: HTMLElement | undefined = this.collapseObj?.el;
    if (contentElement) {

      //Must have listeners
      contentElement.removeEventListener(CollapseEventNames.OnHideStart, this.onHideStart);
      contentElement.removeEventListener(CollapseEventNames.OnShowStart, this.onShowStart);

      if (this.props.onHideStart) {
        contentElement.removeEventListener(CollapseEventNames.OnHideStart, this.props.onHideStart)
      }
      if (this.props.onHideDone) {
        contentElement.removeEventListener(CollapseEventNames.OnHideDone, this.props.onHideDone)
      }
      if (this.props.onShowStart) {
        contentElement.removeEventListener(CollapseEventNames.OnShowStart, this.props.onShowStart)
      }
      if (this.props.onShowDone) {
        contentElement.removeEventListener(CollapseEventNames.OnShowDone, this.props.onShowDone)
      }
      if (this.props.onRemove) {
        contentElement.removeEventListener(CollapseEventNames.OnRemove, this.props.onRemove)
      }
    }
  }

  @autobind
  private onHideStart() {
    this.setState({
      collapsed: true
    });
  }

  @autobind
  private onShowStart() {
    this.setState({
      collapsed: false
    });
  }

  componentDidMount() {
    if (this.buttonRef.current) {
      this.collapseObj = new mwf.Collapse({
        el: this.buttonRef.current,
        toggle: !!this.props.startOpen
      });
      this.setupEventListeners();
    }
  }

  componentWillUnmount() {
    this.removeEventListeners();
    this.collapseObj?.remove();
  }

  render() {

    let buttonClasses = ["btn", "btn-collapse"];
    if (this.state.collapsed) {
      buttonClasses.push("collapsed");
    }
    switch (this.props.buttonSize) {
      case 'small':
        buttonClasses.push('btn-sm');
        break;
      case 'large':
        buttonClasses.push('btn-lg');
        break;
      case 'normal':
      default:
        //Do Nothing
        break;
    }
    return (
      <>
        <p>
          <button ref={this.buttonRef} className={buttonClasses.join(' ')} data-target={`#${this.componentUuid}`} aria-expanded={!this.state.collapsed} aria-controls={`#${this.componentUuid}`}>{this.props.buttonText}</button>
        </p>
        <div className="collapse" id={this.componentUuid}>
          <div>
            {this.props.children}
          </div>
        </div>
      </>
    )
  }
}
import { devOnly } from "../Decorators";

@devOnly
class Debug {
  public error(message?: any, ...optional: any[]) {
    console.error(message, ...optional);
  }

  public info(message?: any, ...optional: any[]) {
    console.log(message, ...optional);
  }

  public warn(message?: any, ...optional: any[]) {
    console.warn(message, ...optional);
  }

  public throw<T extends Error>(error: T) {
    throw error;
  }
}

let debug = new Debug();
export { debug as Debug };
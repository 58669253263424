import { Locale } from "../Locale";

export class MomentLocaleLoader {
  public static load(locale: Locale): Promise<void> {
    //US english comes with moment by default. So we don't need to load it. 
    if (locale !== Locale.English_US && locale !== Locale.LEET_SPEAK) {
      return new Promise((resolve, reject) => {
        import(`moment/locale/${locale}.js`).then(resolve,
          //Handle failed to load 
          () => {
            const splitLocale = locale.split("-");
            const lang = splitLocale[0];
            /**
             * If we are trying to load some other country's english and it isn't there, 
             * it will try to default to US english which we already have loaded.
             */
            if (lang !== locale && lang !== "en") {
              //If we fail again reject, otherwise
              import(`moment/locale/${lang}.js`).then(resolve, reject);
            }
          });
      });
    } else {
      return Promise.resolve();
    }
  }
}
import { autobind } from "@utils/Decorators";
import React from "react";
import { CatalogCheckboxFilter } from "./Filters/CatalogCheckboxFilter";

interface ICatalogFilterGroupProps {
  title: string;
  active: boolean;
  inputChildren?: JSX.Element[];
  checkBox?:boolean;
  enabled?: boolean;
  defaultChecked?:boolean;
  setEnabled? : (enabled:boolean) => void;
}

export class CatalogFilterGroup extends React.Component<ICatalogFilterGroupProps> {


  @autobind
  private setEnabled(enabled: boolean) {
    if (this.props.setEnabled) {
      this.props.setEnabled(enabled);
    }
  }
  
  public render() {
    return (
      <div>
      {this.props.active && <div className="mt-2 mt-lg-3">
        <div className="font-weight-semibold mb-1 mb-lg-2">
         {this.props.checkBox ? <CatalogCheckboxFilter label={this.props.title} defaultChecked={this.props.enabled} onFilterApplied={() => {this.setEnabled(true)}} onFilterRemoved={() => {this.setEnabled(false)}} filterApplied={this.props.enabled} /> : this.props.title }
            <div className="ml-3 ml-lg-4">
              {this.props.inputChildren}
              {this.props.children}
            </div>
          </div>
        </div>}
      </div>
    );
  }
}
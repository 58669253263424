import { AppContext } from "@utils/AppContext";
import React, { useContext } from "react";
import catalogStyles from './NotFound.module.css';


export const NotFound: React.FC = () => {

  const context = useContext(AppContext);

  function render() {
    return (
      <div className={catalogStyles["message"]}>
        <h2>{context.languagePack.not_found_page.heading}</h2>
        <p>{context.languagePack.not_found_page.sub_message}</p>
      </div>
    );
  };

  return render();
}
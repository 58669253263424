import React, { useContext, useEffect } from 'react';
import { AppContext } from '@utils/AppContext';
import { Environment } from '@utils/Environment';
import { Header } from '@components/Header';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Banner } from '@components/MWF/Banner';
import { Catalog } from '@components/Pages/Catalog';
import { NotFound } from '@components/Pages/NotFound/NotFound';
import { TermsOfUse } from '@components/Pages/TermsOfUse';
import { numberOrDefault } from '@utils/TypeUtils';
import { Redirect, StaticContext, Switch, useLocation } from "react-router";
import { SampleComponent } from "@components/SampleComponent";
import { Footer } from "@components/Footer";
import { RepoRoute } from "@components/Routing/RepoRoute";
import { ProductSubRoute } from "@components/Pages/Product/ProductSubRoute";
import { AppRoute } from "@components/Routing/AppRoute";
import { AppInsights } from "@utils/AppInsights";
import {
  analytics,
  clientTelemetryIsEnabledAndConsented,
  pageLoadTimeMetric,
} from "@utils/ClientTelemetry";
import { ProductPage } from "@components/Pages/Product/ProductPage";

const MAIN_CONTENT_ID = "main_content";

export const App: React.FC = () => {
  const context = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    //Track when the page changes using app insights
    let userDidNotPerformSearch = !location.search;
    if (
      clientTelemetryIsEnabledAndConsented() === true &&
      userDidNotPerformSearch
    ) {
      analytics.capturePageViewPerformance(
        {},
        { metricName: pageLoadTimeMetric }
      );
    }
    AppInsights.instance.trackPageView({
      uri: location.pathname,
    });
  }, [location]);

  useEffect(() => {
    // Scroll to top of page when route changes
    window.scrollTo(0, 0);
  }, [location]);

  function renderCatalog(
    props: RouteComponentProps<{}, StaticContext, unknown>
  ) {
    let query = new URLSearchParams(props.history.location.search);
    let page = numberOrDefault(
      query.get(context.languagePack.catalog_page.page_query_param) ?? "",
      -1,
      true
    );
    return (
      <Catalog
        history={props.history}
        page={page === -1 ? undefined : page}
        urlParams={query}
      />
    );
  }

  function getRedirectPath(pathname: string) {
    const matchIdx = pathname.indexOf(AppRoute.Product);
    const locale = pathname.substring(0, matchIdx);
    const ending = pathname.substring(
      matchIdx + AppRoute.Product.length + 1,
      pathname.length
    );
    return `${locale}/artifact/mar/${ending}`;
  }

  function render() {
    const routes = AppRoute.getRoutes(context.locale);
    const subRoutes = [...ProductSubRoute.itr()];
    return (
      <>
        <Header mainContentId={MAIN_CONTENT_ID} />
        <div
          id={MAIN_CONTENT_ID}
          className="container"
          tabIndex={-1}
          role="main"
        >
          <Switch>
            <Route exact path={routes[AppRoute.Root]} render={renderCatalog} />
            <Route exact path={routes[AppRoute.Samples]}>
              <SampleComponent />
            </Route>
            <Route
              exact
              path={routes[AppRoute.Catalog]}
              render={renderCatalog}
            />
            <Route
              exact
              path={routes[AppRoute.TermsOfUse]}
              component={TermsOfUse}
            />
            <Route exact path={routes[AppRoute.Reliability]}>
              <Banner
                darkShaded
                content={{
                  paragraph: {
                    text: "Here's our reliability page.",
                    title: "Reliability Page",
                  },
                }}
              />
            </Route>
            <Route
              path={routes[AppRoute.Product]}
              render={(props) => {
                return (
                  <Redirect to={getRedirectPath(props.location.pathname)} />
                );
              }}
            />
            <RepoRoute
              path={routes[AppRoute.Artifact]}
              ending={subRoutes}
              render={(props) => {
                return (
                  <ProductPage
                    repo={props.repo}
                    regHash={props.regHash}
                    history={props.routeProps.history}
                    page={
                      props.ending === ProductSubRoute.Default
                        ? ProductSubRoute.Tags
                        : props.ending
                    }
                  />
                );
              }}
            />
            <Route exact path={routes[AppRoute.NotFound]}>
              <NotFound />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer useUHF={Environment.useUHF} />
      </>
    );
  }
  return render();
};
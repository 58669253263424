import { DeveloperError } from "@errors/DeveloperError";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Debug } from "./Debug/Debug";


class AppInsights {
  private _instance: ApplicationInsights | undefined;

  public async init(cookiesEnabled: boolean, options?: (appInsights: ApplicationInsights) => void) {

    let path = "/api/v1/settings/applicationinsights/connectionstring";
    let response = await fetch(path);
    try {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: await response.text(),
          disableCookiesUsage: !cookiesEnabled
        }
      });
      //Apply anything else before calling load
      if (options) {
        options(appInsights);
      }
      appInsights.loadAppInsights();
      this._instance = appInsights;
    } catch (e) {
      Debug.warn(`Encountered error setting up app insights, will not monitor. ${e}`);
      //Use an empty one to prevent errors
      this._instance = new ApplicationInsights({ config: {
        disableCookiesUsage: !cookiesEnabled
      } });
    }
  }

  public enableCookies() {
    this._instance?.getCookieMgr().setEnabled(true);
  }

  public get instance() {
    if (!this._instance) {
      throw new DeveloperError("You did not initialize app insights before using it.");
    }
    return this._instance;
  }

}

const appInsights = new AppInsights();

export { appInsights as AppInsights };
import React from "react";
import { autobind } from "@utils/Decorators";
import { DOMUtils } from "@utils/DOMUtils";

interface IRadioOption<T extends string> {
  label?: string,
  value: T,
  invalid?: boolean
}

interface IRadioGroupProps<T extends string> {
  title?: string;
  groupName: string;
  disabled?: boolean;
  options: IRadioOption<T>[];
  inline?: boolean;
  onChange?: (value: T) => void
  defaultValue?: T
}

interface IRadioGroupState<T extends string> {
  selectedItem: T | undefined;
}

export class RadioGroup<T extends string> extends React.Component<IRadioGroupProps<T>, IRadioGroupState<T>> {

  constructor(props: IRadioGroupProps<T>) {
    super(props);
    this.state = {
      selectedItem: props.defaultValue
    };
  }

  private renderOptions() {
    return this.props.options.map((option, index) => (
      <RadioButton
        label={option.label}
        value={option.value}
        groupName={this.props.groupName}
        disabled={this.props.disabled}
        invalid={option.invalid}
        inline={this.props.inline}
        key={`option_${index}`}
        onChange={this.onChange}
        checked={option.value === this.state.selectedItem}
      />
    ));
  }

  @autobind
  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let radio = event.target;
    let value: T = radio.value as T;
    this.setState({
      selectedItem: value
    });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  public render() {

    const legendId = DOMUtils.generateDOMUuid();

    return (
      <fieldset className="form-group" aria-labelledby={legendId}>
        <legend id={legendId}>{this.props.title ?? ""}</legend>
        {this.renderOptions()}
      </fieldset>
    );
  }
}

interface IRadioButtonProps {
  label?: string;
  inline?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  groupName: string;
  value: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
}

class RadioButton extends React.Component<IRadioButtonProps> {

  public render() {

    const id = DOMUtils.generateDOMUuid();
    const wrapperClasses = ["custom-control custom-radio", this.props.inline ? "custom-control-inline" : ""].join(" ");

    return (
      <div className={wrapperClasses}>
        <input
          type="radio"
          className="custom-control-input"
          id={id}
          disabled={this.props.disabled}
          name={this.props.groupName}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        <label htmlFor={id} className="custom-control-label">
          <span className="custom-control-glyph" aria-hidden="true"></span>
          {this.props.label ?? ""}
        </label>
      </div>
    );
  }
}
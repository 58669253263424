import { Button } from "@components/MWF/Button";
import { GridLayout } from "@components/MWF/GridLayout";
import mobileHelper from "@styles/MobileHelper.module.css";
import { IMeControlUser, UserAPI } from "@utils/APIs/UserAPI";
import { AppContext } from "@utils/AppContext";
import { Environment } from "@utils/Environment";
import { useContext, useEffect, useRef, useState } from "react";
import { loginState } from "./Header";
import { Icon } from "@fluentui/react";

import styles from "./UserControl.module.css";

interface IUserControlProps {
  hidden?: boolean;
  loggedIn: loginState;
  user: IMeControlUser | null;
}

export const UserControl: React.FC<IUserControlProps> = (props) => {
  const context = useContext(AppContext);
  const refDisplayName = useRef(null);
  const refMemName = useRef(null);
  const refInfo = useRef(null);
  const refImage = useRef(null);
  let [infoOpen, setInfoOpen] = useState(false);

  useEffect(() =>{
    if(infoOpen)
    {
      document.addEventListener('click',handleClick);
    }
  });

  function handleClick(evt : MouseEvent)
  {
    // If we are clicking outside the info area hide the info area
    if(refInfo.current && refImage.current && refDisplayName.current && refMemName.current
       && (refInfo.current != evt.target) && (refImage.current != evt.target)
       && (refDisplayName.current != evt.target) && (refMemName.current != evt.target) )
    {
      toggleOpen();
      document.removeEventListener('click',handleClick);
    }
  }
  



  function getUserInitials(name?: string) {
    //TODO This is a temporary fix since we don't know how initals work from localization
    return name?.split(" ").filter(part => part !== "").map(part => part.charAt(0).toUpperCase()).join("");
  }

  function renderUserImage(position: "button" | "modal") {
    if (position === "button") {

      if (props.user?.pictureUrl) {
        return <div className={styles["user-image"]} style={{ backgroundImage: `url(${props.user?.pictureUrl})` }} />
      } else {
        //TODO generate letters
        return <div className={`${styles["user-image"]} ${styles.none}`}>{getUserInitials(props.user?.displayName)}</div>;
      }
    } else {
      if (props.user?.pictureUrl) {
        return (
          <GridLayout.Column>
            <div ref={refImage} className={`${styles["user-image"]} ${styles["big"]}`} style={{ backgroundImage: `url(${props.user?.pictureUrl})` }} />
          </GridLayout.Column>
        );
      } else {
        return (
          <GridLayout.Column>
            <div ref={refImage} className={`${styles["user-image"]} ${styles["big"]} ${styles.none}`}>{getUserInitials(props.user?.displayName)}</div>
          </GridLayout.Column>
        );
      }
    }

  }

  function toggleOpen() {
    setInfoOpen(!infoOpen);
  }

  function render() {
    let component = <></>;
    if (!props.hidden) {
      switch (props.loggedIn) {
        case loginState.LoggedIn:
          const greetingName = props.user?.displayName.split(" ")[0] ?? "";
          const greeting = context.languagePack.general.greeting_template.replace("{0}", greetingName);
          component = (
            <div className={styles.container}>
              <Button onClick={toggleOpen} size="small" theme='primary' className={styles["user-button"]}>
                <div className={`${mobileHelper["desktop-only"]} ${styles["display-name"]}`}>{greeting}</div>
                {renderUserImage("button")}
              </Button>
              <div ref={refInfo} className={`${styles["info-area"]} ${infoOpen ? styles.visible : styles.hidden}`}>
                <GridLayout>
                  <GridLayout.Row noGutters classes={[styles["info-data-row"]]}>
                    {renderUserImage("modal")}
                    <GridLayout.Column classes={[styles["info-text"]]}>
                      <div ref={refDisplayName}>{props.user?.displayName}</div>
                      <div ref={refMemName}>{props.user?.memberName}</div>
                    </GridLayout.Column>
                  </GridLayout.Row>
                  <GridLayout.Row noGutters>
                    <Button className={styles["sign-out-button"]} onClick={UserAPI.signout} block theme="primary" >Sign Out</Button>
                  </GridLayout.Row>
                </GridLayout>
              </div>
            </div>
          );
          break;
        case loginState.LoggedOut:
          if (Environment.showAuth) {
            component = (
              <>
                <Button onClick={UserAPI.signin} className={`${mobileHelper["desktop-only"]} ${styles["sign-in-button"]}`} size="small" theme="primary" >Employee Sign In</Button>
                <Button onClick={UserAPI.signin} className={`${mobileHelper["mobile-only"]} ${styles["sign-in-button"]}`} size="small" theme="primary" ariaLabel="Employee Sign In">
                  <Icon
                    aria-label="Sign in"
                    iconName="signin"
                  />
                </Button>
              </>
            );
          }
          break;
        case loginState.Uncertain:
          break;
      }
    }
    return component;
  }

  return render();
}
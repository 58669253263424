import { autobind } from "./Decorators";

type FilterRule<T> = (item: T) => boolean;
type RemoveRuleCallback = () => void;

export class Filter<T> {
  private rules: Set<FilterRule<T>>;

  constructor() {
    this.rules = new Set();
  }

  @autobind
  public passes(item: T) {
    let passes = true;
    for (let rule of this.rules) {
      if (!rule(item)) {
        passes = false;
        break;
      }
    }
    return passes;
  }

  public addRule(rule: FilterRule<T>): RemoveRuleCallback {
    this.rules.add(rule);
    return () => this.removeRule(rule);
  }

  public removeRule(rule: FilterRule<T>): void {
    this.rules.delete(rule);
  }
}
///<reference types="@wcp/wcp-consent" />

import { ApplicationInsights } from '@microsoft/1ds-analytics-web-js';
import { Environment } from '@utils/Environment';

const ClientTelemetry = () => {
    let analytics = new ApplicationInsights();
    let instrumentationKey = Environment.oneDSInstrumentationKey;
    let wcpConsent = false;
    // max number of events that are kept in memory before starting to drop events.
    // events are kept in memory until they're sent via the post channel
    const MAX_EVENTS_STORED = Environment.oneDSMaxEventsStored;

    const initClientTelemetry = (_wcpConsent: boolean) => {
        if (_wcpConsent) {
            wcpConsent = true;
            var config = {
                instrumentationKey: instrumentationKey,
                disableCookiesUsage: !wcpConsent,
                channelConfiguration: {
                    eventsLimitInMem: MAX_EVENTS_STORED
                },
                propertyConfiguration: {
                    dropIdentifiers: true,
                    callback: {
                    },
                },
                webAnalyticsConfiguration: {
                    // Web Analytics Plugin configuration
                    autoCapture: {
                        scroll: false,
                        pageView: false,
                        onLoad: true,
                        onUnload: true,
                        click: false,
                        resize: false,
                        jsError: false,
                    }
                }
            };
            //Initialize SDK
            analytics.initialize(config, []);
        }
    }

    const enableClientTelemetryCookies = () => {
        analytics.getCookieMgr().setEnabled(true);
        wcpConsent = true;
    }

    const clientTelemetryIsEnabledAndConsented = () => {
        return (Environment.isClientTelemetryEnabled && wcpConsent);
    }

    const searchTimeMetric = "searchPerformance";
    const pageLoadTimeMetric = "pageChangePerformance";

    return { analytics, initClientTelemetry, clientTelemetryIsEnabledAndConsented: clientTelemetryIsEnabledAndConsented, enableClientTelemetryCookies: enableClientTelemetryCookies, searchTimeMetric, pageLoadTimeMetric};
}

export const { analytics, initClientTelemetry, clientTelemetryIsEnabledAndConsented, enableClientTelemetryCookies, searchTimeMetric, pageLoadTimeMetric} = ClientTelemetry();
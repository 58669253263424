import { AppContext } from "@utils/AppContext";
import { Dropdown, IDropdownOption } from "@components/MWF/FormComponents/Dropdown";
import { Locale } from "@intl/Locale";

import { UHFButtonIds } from "@utils/UHF/UHFConstants";
import { useContext, useEffect } from "react";

import styles from "./Footer.module.css";
import React from "react";
import ReactDOM from "react-dom";

interface IFooterProps {
  useUHF: boolean;
}

export const Footer: React.FC<IFooterProps> = (props) => {
  const context = useContext(AppContext);
  const localePickerRef = React.createRef<Dropdown>()

  useEffect(() => {
    if (props.useUHF) {
      bindUHF();
    }
  });

  function bindUHF() {
    //Bind the localepicker
    const localePickerElem = document.getElementById(UHFButtonIds.LocalePicker);
    if (localePickerElem) {
      const div = document.createElement('div');
      div.className = styles["locale-picker-container"];
      ReactDOM.render(renderLocalePicker(), div);
      const parent = localePickerElem.parentElement!;
      localePickerElem.remove();
      parent.prepend(div);
    }
  }

  function handleLocaleChanged(newLocale: Locale) {
    const currentLocale = Locale.fromCurrentLocation();
    const currentPath = window.location.pathname;
    const origin = window.location.origin;
    if (currentLocale) {
      const newPath = currentPath.replace(currentLocale, newLocale);
      window.location.href = `${origin}${newPath}`;
    } else {
      //Since it is english with nothing in the url, we can append the locale before the path and that's it.
      window.location.href = `${origin}/${newLocale}${currentPath}`;
    }
  }

  function renderLocalePicker() {
    const options: IDropdownOption[] = [...Locale.itr()].map(locale => (
      {
        label: Locale.toNativeName(locale),
        action: () => handleLocaleChanged(locale)
      }
    ))
    return (
      <div className={styles["locale-picker"]}>
        <Dropdown
          label={Locale.toNativeName(context.locale)}
          options={options}
          direction="up"
          ref={localePickerRef}
          buttonClasses={['c-glyph glyph-world']}
        />
      </div>
    )
  }

  function render() {
    if (props.useUHF) {
      return null;
    } else {
      //TODO implement replacment footer.
      return null;
    }
  }

  return render();
}
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from '@utils/AppContext';
import { useHistory } from "react-router";
import { GridLayout } from "@components/MWF/GridLayout";
import { Input } from "@components/MWF/FormComponents/Input";
import { Icon } from "@fluentui/react";

import headerStyles from "./Header.module.css";
import mobileHelper from "@styles/MobileHelper.module.css";
import { Button } from "@components/MWF/Button";
import { Environment } from "@utils/Environment";
import { IDropdownOption } from "@components/MWF/FormComponents/Dropdown";
import { AppRoute } from "@components/Routing/AppRoute";

import MicrosoftLogo from "@src/Images/ms_header_logo.svg";
import MicrosoftLogoMobile from "@src/Images/ms_logo_mobile.png";
import { UserControl } from "./UserControl";
import { AppInsights } from "@utils/AppInsights";
import { AppInsightEvent } from "@utils/Enums/AppInsightEvent";
import { IMeControlUser, UserAPI } from "@utils/APIs/UserAPI";
import { analytics, clientTelemetryIsEnabledAndConsented, searchTimeMetric } from "@utils/ClientTelemetry";
import { ProductFilterConst } from "@models/ProductFilter";

interface IHeaderProps {
  mainContentId: string;
}

export enum loginState {
  LoggedIn,
  LoggedOut,
  Uncertain
}

export const Header: React.FC<IHeaderProps> = (props) => {

  const history = useHistory();
  const context = useContext(AppContext);
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const searchField = useRef<Input>(null);

  let [usrLoginState, setLoggedIn] = useState(loginState.Uncertain);
  let [user, setUser] = useState<IMeControlUser | null>(null);

  function isUserLoggedIn()
  {
    return (usrLoginState === loginState.LoggedIn);
  }
  

  useEffect(() => {
    
    checkLoginState();

    if (searchVisible) {
      searchField.current?.focus();
    }
  }, [searchVisible]);

  async function checkLoginState() {
    const authed = await UserAPI.getIsLoggedIn();
    if (authed) {
      //we are authed so we know we will have a user
      let user = (await UserAPI.getMeControlUser())!;
      setUser(user);
      setLoggedIn(loginState.LoggedIn);
    } else {
      setLoggedIn(loginState.LoggedOut);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const search = params.get("search") ?? "";
    searchField.current?.setValue(search);
  }, [history.location.search])

  function onLogoClicked() {
    const route = AppRoute.getRoute(AppRoute.Root, context.locale);
    history.push(route);
  }

  function onSamplesClicked() {
    const route = AppRoute.getRoute(AppRoute.Samples, context.locale);
    history.push(route);
  };

  function onCatalogClicked() {
    const route = AppRoute.getRoute(AppRoute.Catalog, context.locale);
    history.push(route);
  }

  function handleSearchClicked() {
    //If search is visible, we should try to run the search
    if (searchVisible) {
      //NO-OP if there isn't a search value
      if (searchQuery) {
        //Track the search
        if (clientTelemetryIsEnabledAndConsented() === true) {
          analytics.capturePageViewPerformance({},{metricName: searchTimeMetric, userSearchQuery: searchQuery}); 
        }
        AppInsights.instance.trackEvent({
          name: AppInsightEvent.Search,
          properties: {
            query: searchQuery
          }
        });
        const route = AppRoute.getRoute(AppRoute.Catalog, context.locale, `?${context.languagePack.catalog_page.search_query_param}=${searchQuery}`);
        history.push(route);
        searchField.current?.reset();
        setSearchVisible(false);
      }
    }
    //otherwise show the search box
    else {
      setSearchVisible(true);
    }
  }

  function handleSearchQueryChanged(newValue: string) {
    setSearchQuery(newValue);
  }

  function handleEnterPressed(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      handleSearchClicked();
    }
  }

  function handleCancelSearch() {
    setSearchQuery("");
    searchField.current?.reset();
    setSearchVisible(false);
    let params = new URLSearchParams(history.location.search);
    params.delete(context.languagePack.catalog_page.search_query_param);
    params.delete(ProductFilterConst.SearchTypeParam);
    history.push({
      search: params.toString()
    });
  }

  function skipToMainContent() {
    document.getElementById(props.mainContentId)?.focus();
  }

  function renderLogo() {
    return (
      <>
        <a
          aria-label="Microsoft"
          title="Microsoft"
          href="https://www.microsoft.com"
          style={{ backgroundImage: `url(${MicrosoftLogo})` }}
          className={`${headerStyles["logo-img-link"]} ${mobileHelper["desktop-only"]}`}
        />
        <div className={`${headerStyles["logo-spacer"]} ${mobileHelper["desktop-only"]}`} role="img" aria-label="divider" />
        <button
          className={`${headerStyles["logo-text"]} ${mobileHelper["desktop-only"]} font-weight-semibold btn`}
          onClick={onLogoClicked}
        >
          {context.languagePack.app_title + (isUserLoggedIn() ? " (Internal) " : "")}
        </button>
        <a
          aria-label="Microsoft"
          title="Microsoft"
          href="https://www.microsoft.com"
          style={{ backgroundImage: `url(${MicrosoftLogoMobile})` }}
          className={`${searchVisible ? headerStyles.hidden : ""} ${headerStyles["logo-img-link"]} ${mobileHelper["mobile-only"]}`}
        />
        <button
          className={`${headerStyles["logo-text"]} ${mobileHelper["mobile-only"]} font-weight-semibold btn ${searchVisible ? headerStyles.hidden : ""}`}
          onClick={onLogoClicked}
        >
          {context.languagePack.app_title_mobile + (isUserLoggedIn() ? " (Internal) " : "")}
        </button>
      </>
    );
  }

  function renderTabButtons() {
    return (
      <>
        {/* <div className={`${mobileHelper["desktop-only"]} ${headerStyles["tab-link-container"]}`}>
          <Button theme="link" className={`${headerStyles["tab-link"]} font-weight-normal`} onClick={onCatalogClicked}>Catalog</Button>
          {Environment.isDevelopment && <Button theme="link" className={`${headerStyles["tab-link"]} font-weight-normal`} onClick={onSamplesClicked}>Samples</Button>}
        </div> */}
      </>
    );
  }

  function renderRight() {
    return (
      <>
        <span className={`${headerStyles["search-box"]} ${headerStyles["search-box"]} ${searchVisible ? "" : headerStyles.hidden}`}>
          <Input
            ref={searchField}
            inline ariaLabel="Search Field"
            placeholder={`${context.languagePack.general.search}...`}
            onChange={handleSearchQueryChanged}
            onKeyPressed={handleEnterPressed}
          />
        </span>
        <Button className={isUserLoggedIn() ? `${headerStyles["button-wrapper"]} ${headerStyles["button-wrapper-internal"]}` : `${headerStyles["button-wrapper"]}`} onClick={handleSearchClicked}>
          <label
            className={`${mobileHelper["desktop-only"]}`}
            style={{ display: searchVisible ? "none" : undefined }}
            aria-hidden="true">
            {context.languagePack.general.search}
          </label>
          <Icon
            aria-label="Search"
            iconName="Search"
            className={`${headerStyles["search-button"]}`}
          />
        </Button>
        <Button
          onClick={handleCancelSearch}
          size="small"
          theme="light"
          modifier="outline"
          className={`ml-2 ${`${headerStyles["cancel-button"]}`} ${searchVisible ? "" : headerStyles.hidden}`}>
          Cancel
        </Button>
      </>
    )
  }

  function render() {

    const options: IDropdownOption[] = [{ label: "Catalog", action: onCatalogClicked }];
    if (Environment.isDevelopment) {
      options.push({ label: "Samples", action: onSamplesClicked })
    }


    return (
      <>
        <div className={headerStyles["skip-to-main"]}>
          <Button onClick={skipToMainContent} theme='link'>Skip to main content</Button>
        </div>
        <nav className={headerStyles.nav + (isUserLoggedIn() ? " " + headerStyles.navInternal : "")}>
          <GridLayout className={`${headerStyles["grid-layout"]} container`}>
            <GridLayout.Row noGutters>
              <GridLayout.Column classes={[headerStyles.left]}>
                {renderLogo()}
                {renderTabButtons()}
              </GridLayout.Column>
              <GridLayout.Column classes={[headerStyles.right]}>
                {renderRight()}
                <UserControl hidden={false} loggedIn={usrLoginState} user={user} />
              </GridLayout.Column>
            </GridLayout.Row>
          </GridLayout>
        </nav>
        {/* We Don't Have NAvigation right now, but we will eventually. */}
        {/* <div className={`${mobileHelper["mobile-only"]} ${headerStyles["dropdown-container"]}`}>
          <Dropdown
            className={headerStyles["tab-link-dropdown"]}
            label="Navigation"
            options={options}
            reflow
          />
        </div> */}
      </>
    )
  }

  return render();

}
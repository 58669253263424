import React from 'react';

interface ISpinnerProps {
  large?: boolean;
  ariaId: string;
}

export const Spinner: React.FC<ISpinnerProps> = (props) => {
  function render() {
    return (
      <div id={props.ariaId} className={["spinner",props.large ? "spinner-lg" : ""].join(" ")} tabIndex={-1} role="alert" aria-live="assertive">
        <span className="sr-only">Content loading...</span>
        <span role="presentation" className="spinner-dot spinner-dot-1"></span>
        <span role="presentation" className="spinner-dot spinner-dot-2"></span>
        <span role="presentation" className="spinner-dot spinner-dot-3"></span>
        <span role="presentation" className="spinner-dot spinner-dot-4"></span>
        <span role="presentation" className="spinner-dot spinner-dot-5"></span>
      </div>
    )
  }

  return render();
}
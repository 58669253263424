import React from "react";
import { autobind } from "@utils/Decorators";
import { DOMUtils } from "@utils/DOMUtils";
import { DeveloperError } from "@errors/DeveloperError";
import { Debug } from "@utils/Debug/Debug";

export type SelectOptionDef<C extends string> = {
  label?: string;
  value: C
}

interface ISelectProps<C extends string> {
  options: SelectOptionDef<C>[];
  customId?: string;
  label?: string;
  labelClasses?: string[]
  applyLabelSpacing?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  inline?: boolean;
  onChange?: (newValue: C, oldValue: C) => void;
}

interface ISelectState<C extends string> {
  value: C
}

export class Select<C extends string> extends React.Component<ISelectProps<C>, ISelectState<C>> {

  constructor(props: ISelectProps<C>) {
    super(props);
    if (props.options.length === 0) {
      Debug.throw(new DeveloperError("Select must have at least 1 option"));
    }
    this.state = {
      value: props.options[0].value
    };
  }

  @autobind
  private renderOption(option: SelectOptionDef<C>, index: number) {
    return (
      <option
        key={`option_${index}`}
        value={option.value}
      >
        {option.label ?? option.value}
      </option>
    );
  }

  @autobind
  private onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    //We know this will be C since that is how options is defined.
    let value = event.target.value as C;
    if (this.props.onChange) {
      this.props.onChange(value, this.state.value);
    }
    this.setState({
      value: value
    });
  }

  public render() {
    const id = this.props.customId ?? DOMUtils.generateDOMUuid();
    const labelClasses = this.props.labelClasses ?? [];
    if (this.props.inline) {
      labelClasses.push("mr-sm-2");
    }
    let control = (
      <>
        {this.props.label && <label htmlFor={id} className={labelClasses.join(" ")}>{this.props.label}</label>}
        {/*only apply spacing if there is no label*/ this.props.applyLabelSpacing && !this.props.label &&
          <label aria-hidden style={{ opacity: 0 }}>Invisible</label>
        }
        <div className="custom-select ">
          <select className={`custom-select-input ${this.props.invalid ? "is-invalid" : ""}`} id={id} onChange={this.onChange}>
            {this.props.options.map(this.renderOption)}
          </select>
          <span className="custom-select-arrow" aria-hidden="true"></span>
        </div>
      </>
    );

    if (this.props.inline) {
      control = (
        <div className="form-inline">
          {control}
        </div>
      )
    }

    return control;
  }
}
export enum ScreenSizeBreakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

export namespace ScreenSizeBreakpoint {
  export function getMinWidthInPixels(val: ScreenSizeBreakpoint) {
    let result: number;
    switch (val) {
      case ScreenSizeBreakpoint.xs:
        result = 0;
        break;
      case ScreenSizeBreakpoint.sm:
        result = 540;
        break;
      case ScreenSizeBreakpoint.md:
        result = 860;
        break;
      case ScreenSizeBreakpoint.lg:
        result = 1084;
        break;
      case ScreenSizeBreakpoint.xl:
        result = 1400;
        break;
    }
    return result;
  }
}
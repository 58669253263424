import { AppContext } from "@utils/AppContext";
import React, { useContext } from "react";
import termsOfUseStyles from './TermsOfUse.module.css';

export const TermsOfUse: React.FC = () => {

  const context = useContext(AppContext);
  const termsOfUseContent = context.languagePack.terms_of_use_page;
  function render() {
    return (
      <div className={termsOfUseStyles["message"]}>
        <h1>{termsOfUseContent.heading}</h1>
        <hr></hr>
        <p className={termsOfUseStyles["last_updated"]}>{termsOfUseContent.last_update}</p>
        <h3>{termsOfUseContent.terms.heading}</h3>
        <p>{termsOfUseContent.terms.body}</p>
        <h3>{termsOfUseContent.services.heading}</h3>
        <p>{termsOfUseContent.services.body}</p>
        <h3>{termsOfUseContent.privacy.heading}</h3>
        <p>{termsOfUseContent.privacy.body}</p>   
        <h3>{termsOfUseContent.artifacts.heading}</h3>
        <p>{termsOfUseContent.artifacts.body}</p> 
        <h3>{termsOfUseContent.artifacts_legal.heading}</h3>
        <p>{termsOfUseContent.artifacts_legal.body}</p> 
        <p>{termsOfUseContent.artifacts_legal.body_one}<a href={termsOfUseContent.artifacts_legal.link}>{termsOfUseContent.artifacts_legal.link}</a>{termsOfUseContent.artifacts_legal.body_two}</p> 
        <h3>{termsOfUseContent.docker.heading}</h3>
        <p>{termsOfUseContent.docker.body}</p> 
        <p>{termsOfUseContent.docker.body_one}</p> 
        <p><strong>{termsOfUseContent.docker.body_a_bold}</strong> {termsOfUseContent.docker.body_a}</p> 
        <div className={termsOfUseStyles["indent"]}>
          <p><strong>{termsOfUseContent.docker.body_i_bold}</strong> {termsOfUseContent.docker.body_i}</p> 
          <p><strong>{termsOfUseContent.docker.body_ii_bold}</strong> {termsOfUseContent.docker.body_ii}</p> 
          <p><strong>{termsOfUseContent.docker.body_iii_bold}</strong> {termsOfUseContent.docker.body_iii}</p> 
          <p><strong>{termsOfUseContent.docker.body_iv_bold} </strong>{termsOfUseContent.docker.body_iv}</p> 
          <p><strong>{termsOfUseContent.docker.body_v_bold}</strong> {termsOfUseContent.docker.body_v}</p> 
        </div>
        <p><strong>{termsOfUseContent.docker.body_b_bold}</strong>{termsOfUseContent.docker.body_b}</p> 
        <p><strong>{termsOfUseContent.docker.body_c_bold}</strong>{termsOfUseContent.docker.body_c}</p> 
        <h3>{termsOfUseContent.conduct.heading}</h3>
        <p>{termsOfUseContent.conduct.body}</p> 
        <div className={termsOfUseStyles["indent"]}>
          <p>{termsOfUseContent.conduct.body_one}</p>
          <p>{termsOfUseContent.conduct.body_two}</p>
          <p>{termsOfUseContent.conduct.body_three}</p>
          <p>{termsOfUseContent.conduct.body_four}</p>
          <p>{termsOfUseContent.conduct.body_five}</p>
          <p>{termsOfUseContent.conduct.body_six}</p>
          <p>{termsOfUseContent.conduct.body_seven}</p>
          <p>{termsOfUseContent.conduct.body_eight}</p>
        </div>
        <p>{termsOfUseContent.conduct.body_nine}</p>
        <h3>{termsOfUseContent.third_party.heading}</h3>
        <p>{termsOfUseContent.third_party.body}</p> 
        <h3>{termsOfUseContent.copyright.heading}</h3>
        <p>{termsOfUseContent.copyright.body} <a href={termsOfUseContent.copyright.link}>{termsOfUseContent.copyright.link}</a> {termsOfUseContent.copyright.body_one}</p> 
        <h3>{termsOfUseContent.termination.heading}</h3>
        <p>{termsOfUseContent.termination.body}</p>
        <h3>{termsOfUseContent.export.heading}</h3>
        <p>{termsOfUseContent.export.body}<a href={termsOfUseContent.export.link}>{termsOfUseContent.export.link}</a>{termsOfUseContent.export.body_one}</p> 
        <h3>{termsOfUseContent.unsolicated.heading}</h3>
        <p>{termsOfUseContent.unsolicated.body}</p>
        <h3>{termsOfUseContent.warranties.heading}</h3>
        <p><strong>{termsOfUseContent.warranties.body}</strong></p>
        <h3>{termsOfUseContent.liability.heading}</h3>
        <p>{termsOfUseContent.liability.body}</p>
        <h3>{termsOfUseContent.entity.heading}</h3>
        <p>{termsOfUseContent.entity.body}</p>
        

      </div>
    );
  };

  return render();
}
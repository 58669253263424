import { DeveloperError } from "@errors/DeveloperError";

class Environment {

  //General Options

  get isDevelopment(): boolean {
    return this.getReactAppEnvironmentBoolean("IS_DEVELOPMENT");
  }

  get showAuth(): boolean {
    return this.getReactAppEnvironmentBoolean("SHOW_AUTH");
  }

  get isUnderConstruction(): boolean {
    return this.getReactAppEnvironmentBoolean("IS_UNDER_CONSTRUCTION");
  }

  get useUHF(): boolean {
    return this.getReactAppEnvironmentBoolean("USE_UHF");
  }

  get isClientTelemetryEnabled(): boolean {
    return this.getReactAppEnvironmentBoolean("IS_CLIENT_TELEMETRY_ENABLED");
  }

  get oneDSInstrumentationKey(): string | undefined {
    return this.getReactAppEnvironmentString("ONEDS_INSTRUMENTATION_KEY");
  }

  get oneDSMaxEventsStored(): number | undefined {
    return this.getReactAppEnvironmentNumber("ONEDS_MAX_EVENTS_STORED");
  }

  //Private functions for loading environment variables.
  private getReactAppEnvironmentString(name: string, isOptional: false): string;
  private getReactAppEnvironmentString(name: string, isOptional: boolean): string | undefined
  private getReactAppEnvironmentString(name: string): string | undefined
  private getReactAppEnvironmentString(name: string, isOptional = true): string | undefined {
    const varName = "REACT_APP_" + name;
    const result = process.env[varName];
    if (isOptional || result) {
      return result;
    } else {
      throw new DeveloperError(`Environment variable with name: ${name} was not defined in the environment file`);
    }
  }

  private getReactAppEnvironmentBoolean(name: string, isOptional = true): boolean {
    return this.getReactAppEnvironmentString(name, isOptional) === 'true';
  }

  private getReactAppEnvironmentNumber(name: string, isOptional = true): number | undefined {
    var result = this.getReactAppEnvironmentString(name, isOptional);
    if (typeof result === "string") {
      return parseInt(result);
    }
    return result;
  }

  private getReactAppEnvironmentList(name: string, isOptional = true): string[] | undefined {
    const result = this.getReactAppEnvironmentString(name, isOptional);
    return result?.split(',');
  }

}


const instance = new Environment();

export { instance as Environment };
import React from "react";
import { ScreenSizeBreakpoint } from "@utils/MWF/ScreenSizeBreakpoints";
import mobileHelperStyles from '@styles/MobileHelper.module.css';



type GridColumnWidth = "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type GridRowColumnAmount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ScreenSizeSettings<T> = [ScreenSizeBreakpoint | 'default', T][];

interface IGridRowProps {
  columnSettings?: [ScreenSizeBreakpoint | 'default', GridRowColumnAmount][];
  children?: (React.ReactElement<IGridColumnProps> | null)[] | React.ReactElement<IGridColumnProps>;
  noGutters?: boolean;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  classes?: string[];
}

interface IGridColumnProps {
  widthSettings?: ScreenSizeSettings<GridColumnWidth>;
  classes?: string[]; //TODO setup some code that will help people specify what they mean. In the meantime https://moray-prod.azurewebsites.net/components/detail/utilities-spacing--margin.html
  /**
    * See https://moray-prod.azurewebsites.net/components/detail/grid.html  > Columns > Options for instructions.
    * This will set the equal width style for the column based on the screen size.
    */
  equalWidthsWhen?: ScreenSizeBreakpoint[];
  mobileOnly?: boolean;
  desktopOnly?: boolean;
}

interface IGridLayoutProps {
  className?: string;
  children?: React.ReactElement<IGridRowProps>[] | React.ReactElement<IGridRowProps>;
}


/**
 * An important thing to note about the GridLayout: Rows will not blend with eachother, 
 * however if you have more items in a row than supported, they will wrap to the next line,
 * forming a sudo "row". 
 */
export class GridLayout extends React.Component<IGridLayoutProps> {

  public render() {
    return (
      <section className={this.props.className}>
        {this.props.children}
      </section>
    )
  }
}

export namespace GridLayout {

  export const Row: React.FC<IGridRowProps> = (props) => {

    function generateClasses() {
      let classes: string[] = props.classes ?? [];
      classes.push("row");
      if (props.columnSettings) {
        for (let [breakpoint, numCols] of props.columnSettings) {
          if (breakpoint === 'default') {
            classes.push(`row-cols-${numCols}`);
          } else {
            classes.push(`row-cols-${breakpoint}-${numCols}`);
          }
        }
      }
      if (props.noGutters) {
        classes.push('no-gutters');
      }
      if (props.mobileOnly) {
        classes.push(mobileHelperStyles["mobile-only"]);
      }
      if (props.desktopOnly) {
        classes.push(mobileHelperStyles["desktop-only"]);
      }
      return classes.join(' ');
    }

    function render() {
      return (
        <div className={generateClasses()}>
          {props.children}
        </div>
      );
    }

    return render();
  }

  export const Column: React.FC<IGridColumnProps> = (props) => {

    function generateClasses() {
      let classes: string[] = ["col"];
      if (props.widthSettings) {
        for (let [breakpoint, width] of props.widthSettings) {
          if (breakpoint === 'default') {
            classes.push(`col-${width}`);
          } else {
            //Equal width will take precedence over a set width
            if (!props.equalWidthsWhen?.includes(breakpoint)) {
              classes.push(`col-${breakpoint}-${width}`);
            }
          }
        }
      }
      if (props.equalWidthsWhen) {
        for (let breakpoint of props.equalWidthsWhen) {
          classes.push(`col-${breakpoint}`);
        }
      }
      if (props.classes) {
        classes.push(props.classes.join(' '));
      }

      if (props.mobileOnly) {
        classes.push(mobileHelperStyles["mobile-only"]);
      }
      if (props.desktopOnly) {
        classes.push(mobileHelperStyles["desktop-only"]);
      }
      return classes.join(' ');
    }

    function render() {
      return (
        <div className={generateClasses()}>
          {props.children}
        </div>
      );
    }

    return render();
  }
}
export interface IMeControlUser {
  type: 'aad',
  authenticatedState: 'signedIn' | '',
  memberName: string,
  displayName: string,
  pictureUrl: string
}

export namespace UserAPI {
  export async function getMeControlUser(): Promise<IMeControlUser | undefined> {
    let response = await fetch("/api/v1/user/me");
    if (response.ok) {
      //The cookie was bad, reload the page
      if (response.status === 205) {
        window.location.reload();
      } else {
        let data: IMeControlUser = await response.json();
        return data;
      }
    } else {
      return undefined;
    }
  }

  export async function getIsLoggedIn(): Promise<boolean> {
    let response = await fetch("/api/v1/user/auth/state");
    if (response.ok) {
      let data: { authenticated: boolean } = await response.json();
      return data.authenticated;
    } else {
      return false;
    }
  }

  export function signout(): void {
    window.location.href = `/api/v1/user/auth/signout?redirect=`;
  }

  export function signin(): void {
    window.location.href = `/api/v1/user/auth/signin?redirect=${window.location.pathname}`;
  }
}
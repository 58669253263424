///<reference types="@wcp/wcp-consent" />

import { AppInsights } from "@utils/AppInsights";
import { enableClientTelemetryCookies } from "@utils/ClientTelemetry";
import { Environment } from "./Environment";

export class WCPConsent {
    private siteConsent: WcpConsent.SiteConsent | undefined;
    //Init method 
    constructor (locale: string) {
        WcpConsent && WcpConsent.init(
            locale, 
            "cookie-banner", 
            (err, _siteConsent) => {
                if (err) {
                    alert(err);
                } else {
                    this.siteConsent = _siteConsent!;
                }
            }, 
            this.onConsentChanged);
    }
    

    //call back method when consent is changed by user
    public onConsentChanged(newConsent: Record<WcpConsent.consentCategories, boolean>) {
        if (newConsent.Analytics) {
            AppInsights.enableCookies();
            if (Environment.isClientTelemetryEnabled) {
                enableClientTelemetryCookies();
            }
        }
    }

    public manageConsent() {
        this.siteConsent!.manageConsent();
    }

    public getAnalyticsConsent() {
        return this.siteConsent?.getConsentFor(WcpConsent.consentCategories.Analytics) ?? false;
    }
}
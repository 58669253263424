import { HttpStatusCode } from "@utils/Http/HttpStatusCode";

export class HTTPError extends Error {

  public readonly userMessage: string;
  public readonly statusCode: HttpStatusCode;

  constructor(status: HttpStatusCode, userMessage: string) {
    super(`Http request failed with status ${status}`);
    this.userMessage = userMessage;
    this.name = "HTTPError";
    this.statusCode = status;
  }
}
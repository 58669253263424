import React from "react";
import { autobind } from "@utils/Decorators";
import { DOMUtils } from "@utils/DOMUtils";

interface ICheckboxProps {
  label?: string;
  customId?: string;
  inline?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

interface ICheckboxState {
  checked: boolean;
}

export class Checkbox extends React.Component<ICheckboxProps,ICheckboxState> {

  constructor(props: ICheckboxProps) {
    super(props);
    this.state = {
      checked: !!this.props.defaultChecked
    }
  }

  static getDerivedStateFromProps(props: ICheckboxProps, state: ICheckboxState) {
    if (props.checked !== undefined && props.checked !== state.checked) {
      return {
        checked: props.checked
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  @autobind
  private onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let box = event.target;
    let checked = box.checked;
    this.setState({
      checked: checked
    });
    if (this.props.onChange) {
      this.props.onChange(checked);
    }
  }

  public render() {

    const id = this.props.customId ?? DOMUtils.generateDOMUuid();
    const wrapperClasses = ["custom-control custom-checkbox", this.props.inline ? "custom-control-inline" : ""].join(" ");

    return (
      <div className={wrapperClasses}>
        <input type="checkbox" className="custom-control-input" id={id} disabled={this.props.disabled} checked={this.state.checked} onChange={this.onChange} />
        <label htmlFor={id} className="custom-control-label">
          <span className="custom-control-glyph" aria-hidden="true"></span>
          {this.props.label ?? ""}
        </label>
      </div>
    )
  }
}
//These polyflls must be first
import '@utils/Polyfills';

import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';

import { Locale } from '@intl/Locale';
import { AppContext, IAppContext } from '@utils/AppContext';
import { App } from './App';
import { Environment } from '@utils/Environment';
import { initializeIcons } from '@fluentui/react';
import { LanguagePackLoader } from '@intl/LanguagePack';

import constructionStyles from './Styles/Construction.module.css';
//Global Styles
import './Styles/global.css';
import { MomentLocaleLoader } from '@intl/DateTime/MomentLocaleLoader';
import { AppInsights } from '@utils/AppInsights';
import { BrowserRouter } from 'react-router-dom';
import { WCPConsent } from '@utils/WCPConsent';
import { initClientTelemetry } from '@utils/ClientTelemetry';

function construction() {
  ReactDOM.render(
    <React.StrictMode>
      <div key='label' aria-level={0} role="heading" className="sr-only">Under Construction</div>,
      <div key='image' className={constructionStyles.image} style={{ backgroundImage: "url('./images/underconstruction.png')" }}></div>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

async function loadLanguage() {
  //Get the current locale from the URL
  const locale = Locale.fromCurrentLocation(Locale.English_US);
  //Load the correct language pack.
  const langPack = await LanguagePackLoader.load(locale);
  //Create the app context to share the locale data
  const ctx: IAppContext = {
    locale: locale,
    languagePack: langPack
  }
  //Update the title
  document.title = langPack.app_title;
  //Update the global language for the html document
  document.documentElement.lang = locale;
  //Load moment language    
  try {
    await MomentLocaleLoader.load(locale);
    moment.locale(locale);
  } catch (err) {
    console.warn(err);
  }
  //Load native names if Intl isn't supported
  await Locale.initNativeNames();

  return ctx;
}

async function start() {
  //Load Language
  let ctx = await loadLanguage();

  //Load cookie banner
  let wcp = new WCPConsent(ctx.locale);
  let userConsent = wcp.getAnalyticsConsent();

  //Load client-side telemetry
  if (Environment.isClientTelemetryEnabled) {
    initClientTelemetry(userConsent);
  }

  //Load application insights
  await AppInsights.init(userConsent, insights => {
    insights.addTelemetryInitializer(item => {
     // console.log(item);
    });
  });


  //Fluent UI Icons
  initializeIcons();
  ReactDOM.render(
    <React.StrictMode>
      <AppContext.Provider value={ctx}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AppContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

if (Environment.isUnderConstruction) {
  construction();
} else {
  start();
}
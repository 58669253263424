import React from 'react';
import { FormGroup } from '@components/MWF/FormComponents/FormGroup';
import { RadioGroup } from '@components/MWF/FormComponents/RadioGroup';

type CatalogFilterRadioOption<T extends string> = {
  label?: string,
  value: T
}

interface ICatalogRadioFilterProps<T extends string> {
  name: string;
  options: CatalogFilterRadioOption<T>[]
  label?: string
  disabled?:boolean
  defaultSelected?:T
  setSelected(value:T): void
}

export class CatalogRadioFilter<T extends string> extends React.Component<ICatalogRadioFilterProps<T>> {
  public render() {
    return (
      <FormGroup>
        <RadioGroup inline groupName={this.props.name} onChange={(value)=>this.props.setSelected(value)} defaultValue={this.props.defaultSelected} disabled={this.props.disabled} options={this.props.options} title={this.props.label} />
      </FormGroup>
    );
  }
}
import { EnglishUS, LanguagePack } from "@intl/LanguagePack";
import { Locale } from "@intl/Locale";
import React from "react";

/**
 * Importing these must come first in any file
 */

export interface IAppContext {
  locale: Locale;
  languagePack: LanguagePack;
}

export const AppContext = React.createContext<IAppContext>({ locale: Locale.English_US, languagePack: EnglishUS });
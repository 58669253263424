import React, { useState } from "react";
import { IconButton } from "@fluentui/react";
import { ClipBoard } from "@utils/General/ClipBoard";
import styles from "./CopyableLabel.module.css";

interface ICopyableLabelProps {
  text: string;
  className?: string;
  buttonAriaLabel: string;
  tabIndex?: number;
  tooltip?: string;
}

export const CopyableLabel: React.FC<ICopyableLabelProps> = (props) => {
  const [tooltipText, setTooltipText] = useState(props.tooltip);
  const [tooltipVisible, setTooltipVisibility] = useState(false);

  const copyText = () => {
    ClipBoard.copy(props.text);
    setTooltipText("Copied!");
    setTooltipVisibility(true);
    setTimeout(() => {
      setTooltipText(props.tooltip);
      setTooltipVisibility(false);
    }, 1000);
  };

  return (
    <div className={`${styles.container} ${props.className ?? ""}`}>
      <div className={styles.wrapper}>
        <div className={styles.text}>{props.text}</div>
        <div>
          <IconButton
            aria-label="Copy"
            iconProps={{ iconName: "Copy", className: styles["copy-icon"] }}
            className={styles["copy-button"]}
            onClick={copyText}
            onMouseOver={() => setTooltipVisibility(true)}
            onMouseLeave={() => setTooltipVisibility(false)}
            ariaLabel={props.buttonAriaLabel}
            tabIndex={props.tabIndex ?? 0}
          />
          {tooltipVisible && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                marginBottom: "8px",
                padding: "5px 10px",
                borderRadius: "0px",
                border: "1px solid black",
                backgroundColor: "white",
                color: "black",
                fontSize: "12px",
                whiteSpace: "nowrap",
                zIndex: 1,
              }}
            >
              {tooltipText}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
